<template>
    <div>
        <list-account v-if="view===1" @callback="callBack($event)"></list-account>
        <create-account v-if="view===2" @callback="callBack($event)"></create-account>
        <update-account v-if="view===3" @callback="callBack($event)" :user_update="user_update"></update-account>
    </div>
</template>

<script>
import ListAccount from "@/pages/customer/account/list_account.vue";
import CreateAccount from "@/pages/customer/account/create_account.vue";
import UpdateAccount from "@/pages/customer/account/update_account.vue";

export default {
    components: {
        'listAccount': ListAccount,
        'createAccount': CreateAccount,
        'updateAccount': UpdateAccount,
    },
    data() {
        return {
            view: 1,
            user_update: {},
        };
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.user_update = step.user_update ? step.user_update : {}
        },
    },
};
</script>

