<template>
    <div>
        <Breadcrumbs main="Dashboard" title="index"/>

        <div class="container-fluid">
            <div class="row widget-grid">
                <WelcomeCard/>
                <PurchaseSaleCard/>
                <OrdersProfitCard/>
            </div>
        </div>

    </div>
</template>

<script>
import WelcomeCard from "@/pages/dashboard/index/WelcomeCard.vue";
import PurchaseSaleCard from "@/pages/dashboard/index/PurchaseSaleCard.vue";
import OrdersProfitCard from "@/pages/dashboard/index/OrdersProfitCard.vue";

export default {
    components: {
        WelcomeCard,
        PurchaseSaleCard,
        OrdersProfitCard,
    },
};
</script>
