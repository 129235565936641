<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalCreateAction">Thêm mới action</h4>
                <button @click="resetForm('createForm')" class="btn-close" type="button" data-bs-dismiss="modal" id="close_modal_create_action" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <el-form
                        label-position="left"
                        ref="createForm"
                        :model="formCreate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">ID action<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="id" class="col-md-9 col-10">
                            <el-input v-model="formCreate.id" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên action<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="name" class="col-md-9 col-10">
                            <el-input v-model="formCreate.name" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Menu ID<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="menu_id" class="col-md-9 col-10">
                            <el-input v-model="formCreate.menu_id" placeholder="" disabled/>
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click.prevent="onSubmit('createForm')">
                            Thêm mới
                        </button>
                        <button class="btn btn-light mx-3" @click.prevent="resetForm('createForm')">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import {post} from "@/m_utils/rest_api";
import $ from "jquery";

export default {
    props: ['menu_update'],
    data() {
        return {
            formCreate: {
                id: '',
                name: '',
                menu_id: '',
            },
            rules: {
                id: [
                    {required: true, message: 'Vui lòng nhập ID của action', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const pattern = /^[A-Z_]+$/;
                            if (!pattern.test(value)) {
                                callback(new Error('ID chỉ được chứa các ký tự in hoa và dấu _'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    },
                ],
                name: [
                    {required: true, message: 'Vui lòng nhập tên của action', trigger: 'change'},
                ],
            }
        }
    },
    methods: {
        resetForm(createForm) {
            this.$refs[createForm].resetFields();
        },
        onSubmit(createForm) {
            this.$refs[createForm].validate((valid) => {
                if (valid) {
                    this.onCreate()
                } else {
                    return false;
                }
            });
        },
        async onCreate() {
            let url = '/admin/menu/menu-action'
            let params = {
                name: this.formCreate.name,
                menu_id: this.menu_update.id,
            }
            if (this.formCreate.id.endsWith(this.menu_update.id)) {
                params.id= this.formCreate.id
            } else if (this.formCreate.id.endsWith('_')) {
                params.id= this.formCreate.id + this.menu_update.id
            } else {
                params.id= this.formCreate.id+'_' + this.menu_update.id
            }
            const res = await post(url, params)
            if (res.code === 200) {
                this.$swal({
                    icon: 'success',
                    title: "Thông báo",
                    text: 'Thêm mới action thành công',
                }).then((result) => {
                    if (result.value) {
                        this.$refs['createForm'].resetFields();
                        $("#close_modal_create_action").click()
                        this.$emit("success");
                    } else {
                        this.$refs['createForm'].resetFields();
                        $("#close_modal_create_action").click()
                        this.$emit("success");
                    }
                });
            } else {
                this.$swal({
                    icon: 'error',
                    title: "Thông báo",
                    text: res.message || 'Thêm mới menu thất bại',
                })
            }
        },
    },
    beforeMount() {
        this.formCreate.menu_id = this.menu_update.id
    }
}
</script>

<style scoped>

</style>