<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalUpdateMenu">Cập nhật menu: {{menuUpdate.name}}</h4>
                <button @click="resetForm('updateForm')"  class="btn-close" type="button" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <el-form
                        label-position="left"
                        ref="updateForm"
                        :model="formUpdate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">ID menu <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="id" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.id" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên menu <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="name" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.name" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Level menu <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="level" class="col-md-9 col-10">
                            <el-radio-group v-model="formUpdate.level">
                                <el-radio :label="1"> 1 </el-radio>
                                <el-radio :label="2"> 2 </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="row w-100" v-if="formUpdate.level== 2">
                        <label class="col-md-3 col-2 align-content-center f-16">ID menu level 1 <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="parent_id" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.parent_id" placeholder=""/>
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click.prevent="onSubmit('updateForm')">
                            Cập nhật
                        </button>
                        <button class="btn btn-light mx-3" @click.prevent="resetForm('updateForm')">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import {put} from "@/m_utils/rest_api";
import $ from "jquery";

export default {
    props:['menuUpdate'],
    data() {
        return {
            formUpdate: {
                id: '',
                level: '',
                name: '',
                parent_id: '',
            },
            rules: {
                id: [
                    {required: true, message: 'Vui lòng nhập ID của menu', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const pattern = /^[A-Z_]+$/;
                            if (!pattern.test(value)) {
                                callback(new Error('ID chỉ được chứa các ký tự in hoa và dấu _'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    },
                ],
                name: [
                    {required: true, message: 'Vui lòng nhập tên của menu', trigger: 'change'},
                ],
                level: [
                    {required: true, message: 'Vui lòng chọn level của menu', trigger: 'change'},
                ],
                parent_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.formUpdate.level == 2 && !value) {
                                callback(new Error('Vui lòng nhập ID menu cha cho level bằng 2'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    },
                    {
                        validator: (rule, value, callback) => {
                            const pattern = /^[A-Z_]+$/;
                            if (!pattern.test(value)) {
                                callback(new Error('ID chỉ được chứa các ký tự in hoa và dấu _'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    },
                ],
            }
        }
    },
    methods:{
        resetForm(updateForm) {
            this.$refs[updateForm].resetFields();
        },
        onSubmit(updateForm) {
            this.$refs[updateForm].validate((valid) => {
                if (valid) {
                    this.onCreate()
                } else {
                    return false;
                }
            });
        },
        async onCreate(){
            let url='/admin/menu/menu-item/'+this.menuUpdate.id
            let params ={
                id: this.formUpdate.id,
                level: this.formUpdate.level,
                name: this.formUpdate.name,
                parent_id: this.formUpdate.parent_id,
            }
            const res = await put(url,params)
            if(res.code === 200){
                this.$swal({
                    icon: 'success',
                    title: "Thông báo",
                    text: 'Cập nhật menu thành công',
                }).then((result) => {
                    if (result.value) {
                        this.$refs['updateForm'].resetFields();
                        $("#close").click()
                        this.$emit("success");
                    } else {
                        this.$refs['updateForm'].resetFields();
                        $("#close").click()
                        this.$emit("success");
                    }
                });
            }else {
                this.$swal({
                    icon: 'error',
                    title: "Thông báo",
                    text: res.message || 'Cập nhật menu thất bại',
                })
            }
        },
        setValueForm(menu){
            this.formUpdate.id= menu.id
            this.formUpdate.name= menu.name
            this.formUpdate.level = menu.level
            this.formUpdate.parent_id = menu.parent_id
        }
    },
    watch:{
        menuUpdate: function (newVal, oldVal) {
            if(newVal){
                this.setValueForm(newVal)
            }
        }
    }
}
</script>

<style scoped>

</style>