<template>
    <list-package v-if="view===1" @callback="callBack($event)"></list-package>
    <create-package v-if="view===2" @callback="callBack($event)"></create-package>
    <update-package v-if="view===3" @callback="callBack($event)" :package_update="package_update"></update-package>

</template>
<script>
import ListPackage from '@/pages/package/list_package.vue'
import CreatePackage from '@/pages/package/create_package.vue'
import UpdatePackage from '@/pages/package/update_package.vue'

export default {
    components: {
        'listPackage': ListPackage,
        'createPackage': CreatePackage,
        'updatePackage':UpdatePackage,
    },
    data() {
        return {
            view: 1,
            package_update: {}
        }
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.package_update = step.package_update ? step.package_update : {}
        },
    },
}
</script>
<style>

</style>