import {createStore} from 'vuex'

import layout from './modules/layout';
import menu from './modules/menu';

export default createStore({
    state: {
        show_loading: false,
        isActive: false,
        alert_login: ''
    },
    getters: {
        show_loading: (state) => {
            return state.show_loading
        },
        alert_login: (state) => {
            return state.alert_login
        }
    },
    mutations: {
        change(state) {
            state.isActive = !state.isActive
        },
        set_user(state, user) {
            state.user = user;
        },
        set_loading(state, status) {
            state.show_loading = status;
        },
        set_alert(state, alert_text) {
            state.alert_login = alert_text
        }
    },
    actions: {
        setUser({commit}, user) {
            commit('set_user', user);
        },
        setLoading({commit}, status) {
            commit('set_loading', status);
        },
        setAlert({commit}, alert_text) {
            commit('set_alert', alert_text)
        }
    },
    modules: {
        alert,
        layout,
        menu,
    }
});

