import {createWebHistory, createRouter} from "vue-router";
import VueCookies from 'vue-cookies'
import Body from '@/components/body';

/* Dashboards */
import Default from '@/pages/dashboard/index';
import Menu from '@/pages/menu/index';
import Account from '@/pages/account/index';
import System from '@/pages/system/index';
import Gym from '@/pages/gym/index';
import Service from '@/pages/service/index';
import Customer_Account from '@/pages/customer/account/index';
import Role from '@/pages/role_management/index.vue'
import Product_Gym from '@/pages/product_gym/index.vue'
import Package from '@/pages/package/index.vue'
import Error400 from '@/pages/error/error400';
import Error401 from '@/pages/error/error401';
import Error403 from '@/pages/error/error403';
import Error404 from '@/pages/error/error404';
import Error500 from '@/pages/error/error500';
import Error503 from '@/pages/error/error503';


/* Auth */
import login from '../auth/login';
import store from '/src/store'
import constant, {Secret_Key} from '/src/constants/config'
import CryptoJS from "crypto-js";

const routes = [
    {
        path: '/',
        component: Body,
        children: [
            {
                path: '/',
                name: 'indexRoot',
                component: Default,
                meta: {
                    id:'DASH_BOARD',
                    title: 'Vfitness',
                }
            },
            {
                path: '/menu',
                name: 'menuRoot',
                component: Menu,
                meta: {
                    id:'QL_MENU',
                    title: 'Quản lý cấu hình menu | Vfitness',
                }
            },
            {
                path: '/account',
                name: 'AccountRoot',
                component: Account,
                meta: {
                    id: "QL_TAI_KHOAN",
                    title: 'Quản lý tài khoản hệ thống phòng tập | Vfitness',
                }
            },
            {
                path: '/system',
                name: 'SystemRoot',
                component: System,
                meta: {
                    id: "QL_HE_THONG",
                    title: 'Quản lý hệ thống | Vfitness',
                }
            },
            {
                path: '/gym',
                name: 'GymRoot',
                component: Gym,
                meta: {
                    id: "QL_PHONG_TAP",
                    title: 'Quản lý phòng tập | Vfitness',
                }
            },
            {
                path: '/service',
                name: 'ServiceRoot',
                component: Service,
                meta: {
                    id: "QL_DICH_VU",
                    title: 'Quản lý dịch vụ | Vfitness',
                }
            },
            {
                path: '/role-management',
                name: 'RoleRoot',
                component: Role,
                meta: {
                    id: "QL_RESOURCE",
                    title: 'Quản lý phân quyền tài khooản | Vfitness',
                }
            },
            {
                path: '/product-gym',
                name: 'ProductGymRoot',
                component: Product_Gym,
                meta: {
                    id: "QL_SAN_PHAM_PHONG_TAP",
                    title: 'Quản lý sản phẩm phòng tập | Vfitness',
                }
            },
            {
                path: '/package',
                name: 'PackageRoot',
                component: Package,
                meta: {
                    id: "QL_GOI_TAP",
                    title: 'Quản lý gói tập | Vfitness',
                }
            },
        ],
    },
    {
        path: '/customer',
        component: Body,
        children: [
            {
                path: 'account',
                name: 'AccountCustomer',
                component: Customer_Account,
                meta: {
                    id: "TAI_KHOAN_KHACH_HANG",
                    title: 'Quản lý tài khoản khách hàng | Vfitness',
                }
            },
        ],
    },
    {
        path: '/error-400',
        name: 'Error400',
        component: Error400,
        meta: {
            title: 'Error400 | Vfitness',
        }
    },
    {
        path: '/error-401',
        name: 'Error401',
        component: Error401,
        meta: {
            title: 'Error401 | Vfitness',
        }
    },
    {
        path: '/error-403',
        name: 'Error403',
        component: Error403,
        meta: {
            title: 'Error403 | Vfitness',
        }
    },
    {
        path: '/error-404',
        name: 'Error404',
        component: Error404,
        meta: {
            title: 'Error404 | Vfitness',
        }
    },
    {
        path: '/error-500',
        name: 'Error500',
        component: Error500,
        meta: {
            title: 'Error500 | Vfitness',
        }
    },
    {
        path: '/error-503',
        name: 'Error503',
        component: Error503,
        meta: {
            title: 'Error503 | Vfitness',
        }
    },
    {
        path: '/auth',
        children: [
            {
                path: 'login',
                name: 'Login 1',
                component: login,
                meta: {
                    title: ' Đăng nhập  | Vfitness CMS',
                }
            },
        ]
    },
]

function getUserAndRole() {
    let user = VueCookies.get('user');
    let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
    let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedUser.role
}
function getMenu(){
    let user = VueCookies.get('user');
    let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
    let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedUser.list_menu_item_id
}
const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    const authPaths = ['/auth/login'];
    // let timeSession = localStorage.getItem('sessionExpiryTime');
    // if (timeSession < Date.now()) {
    //     if (timeSession) {
    //         store.dispatch('setAlert', 'Phiên làm việc đã kết thúc vui lòng đăng nhập lại')
    //     }
    //     localStorage.clear()
    // }
    const userLoggedIn = VueCookies.get('user');
    if (authPaths.includes(to.path)) {
        // Nếu người dùng đã đăng nhập, không cho phép truy cập trang login và register
        if (userLoggedIn) {
            return next('/');
        } else {
            return next();
        }
    }

    // Nếu người dùng chưa đăng nhập, chuyển hướng đến trang login
    if (!userLoggedIn) {
        VueCookies.keys().forEach(cookie => {
            VueCookies.remove(cookie);
        });
        return next('/auth/login');
    }
    const role = getUserAndRole()
    const list_menu_item_id= getMenu()
    if(to.meta.id && role!== constant.SUPER_ROOT && !list_menu_item_id.includes(to.meta.id)){
        return next('/error-404');
    }
    if (to.path === '/menu' && role !== constant.SUPER_ROOT) {
        return next('/error-404');
    }
    // Kiểm tra nếu router không tồn tại
    if (!router.getRoutes().some(route => route.path === to.path)) {
        return next('/error-404');
    }

    next();
});
export default router