<template>
    <div>
        <Breadcrumbs title="Quản lý cấu hình menu"/>
        <div class="container-fluid">
            <div class="row justify-content-end mb-3">
                <button  class="btn btn-success" style="width:150px" data-bs-toggle="modal"
                         data-bs-target="#modalCreateMenu">Thêm mới
                </button>
            </div>
        </div>
        <div class="row widget-grid">
            <div class="table-responsive">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="tableData.length==0 && show">
                    <p>Không tìm thấy kết quả phù hợp</p>
                    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <table class="table table-bordered table-hover" v-if="tableData.length>0">
                    <thead class="bg-primary">
                    <tr>
                        <th scope="col" width="50px">STT</th>
                        <th scope="col">Tên menu</th>
                        <th scope="col">Menu ID</th>
                        <th scope="col">Level Menu</th>
                        <th scope="col">Menu cha</th>
                        <th scope="col" style="width:15rem">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>{{ getIndex(index) }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.id }}</td>
                        <td>{{ item.level }}</td>
                        <td>{{ item.parent_id }}</td>

                        <td>
                            <button title="Xem thông tin menu"
                                    class="btn btn-primary mt-sm-2 me-2">
                                <i class="fa fa-eye"></i>
                            </button>
                            <button title="Cấu hình các action cho menu"
                                    @click="updateMenuAction(item)"
                                    class="btn btn-info mt-sm-2 me-2">
                                <i class="fa fa-cogs"></i>
                            </button>
                            <button title="Sửa thông tin menu" data-bs-toggle="modal"
                                    data-bs-target="#modalUpdateMenu" @click="updateMenu(item)"
                                    class="btn btn-warning mt-sm-2 me-2" type="button">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button title="Xóa menu" @click="deleteMenu(item)"
                                    class="btn btn-secondary mt-sm-2" type="button">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div v-if="pagging.totalPage>0" class="mt-3">
            <el-pagination
                    v-model:current-page="pagging.current_page"
                    v-model:page-size="pagging.size"
                    :page-sizes="[20, 50, 100]"
                    layout="total, sizes, prev, pager, next"
                    :total="pagging.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
        <div class="modal fade" id="modalCreateMenu" tabindex="-1" role="dialog"
             aria-labelledby="modalCreateMenu" aria-hidden="true">
            <ModalCreate @success ="createSuccess"/>
        </div>
        <div class="modal fade" id="modalUpdateMenu" tabindex="-1" role="dialog"
             aria-labelledby="modalUpdateMenu" aria-hidden="true">
            <ModalUpdate @success ="createSuccess" :menuUpdate="menuUpdate"/>
        </div>
    </div>
</template>
<script>
import {get, remove} from "@/m_utils/rest_api";
import ModalCreate from '@/pages/menu/modal/modal_create.vue'
import ModalUpdate from '@/pages/menu/modal/modal_update.vue'
export default {
    components: {ModalCreate,ModalUpdate},
    data() {
        return {
            callback: {
                view: '',
                menu_update: '',
            },
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            show: false,
            start: 0,
            limit: 20,
            rules: {},
            tableData: [],
            menuUpdate:{},
        }
    },
    methods: {
        updateMenu(item){
            this.menuUpdate= item
        },
        updateMenuAction(item){
            this.callback.menu_update= item
            this.callback.view= 2
            this.$emit("callback", this.callback);
        },

        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },
        async getDuLieu() {
            this.$store.dispatch('setLoading', true);
            let params = {
                'start': this.start,
                'limit': this.limit,
            }
            let url = '/admin/menu/menu-item'
            try {
                const res = await get(url, params)
                if (res.code === 200) {
                    if (res.list_data.length > 0) {
                        this.tableData = res.list_data
                        this.pagging.total = res.total
                        this.pagging.totalPage = res.total / this.pagging.size
                    } else {
                        this.tableData = [];
                        this.pagging.total = 0;
                        this.pagging.totalPage = 0;
                        this.show = true;
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.pagging.total = 0;
                    this.pagging.totalPage = 0;
                    this.show = true;
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.tableData = [];
                this.pagging.total = 0;
                this.pagging.totalPage = 0;
                this.show = true;
                this.$store.dispatch('setLoading', false);
            }

        },
        getIndex(index) {
            return (this.pagging.current_page - 1) * this.limit + index + 1
        },
        createSuccess(){
            this.getDuLieu()
        },
        deleteMenu(item){
            this.$swal({
                icon: 'question',
                text: 'Bạn có muốn xóa menu này?',
                showCancelButton: true,
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#e64942',
                cancelButtonText: 'Đóng',
                cancelButtonColor: '#efefef',
                reverseButtons: true,
                focusCancel: true,
            }).then(async (result) => {
                if (result.value) {
                    let url = '/admin/menu/menu-item/' + item.id;
                    const res = await remove(url)
                    if (res.code === 200) {
                        this.$swal({
                            icon: 'success',
                            title: "Thông báo",
                            text: 'Xóa menu thành công',
                        });
                        this.getDuLieu()
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: "Thông báo",
                            text: 'Xóa menu thất bại',
                        });
                        this.getDuLieu()
                    }
                } else {
                    this.$swal.close()
                }
            });
        },

    },
    mounted() {
        this.getDuLieu();
    }

}
</script>
<style scoped>

</style>