<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalUpdateGym">Cập nhật phòng tập sử dụng dịch vụ: {{ service.name }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row w-100">
                    <label class="col-md-3 col-2 align-content-center f-16">Hệ thống</label>
                    <label class="col-md-9 col-2 align-content-center f-16"><strong>{{
                            service.gymName
                        }}</strong></label>
                </div>
                <div class="row w-100">
                    <label class="col-md-3 col-2 align-content-center f-16">Phòng tập áp dụng</label>
                    <div class="col-md-9 col-10">
                        <div class="mt-2" v-for="(item, index) in lstGym">
                            <el-checkbox :label="item.name" v-model="item.choose" :value="item.choose" size="large"
                                         border/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import constant from '/src/constants/config'
import {get} from '/src/m_utils/rest_api';

export default {
    props: ["service"],
    data() {
        return {
            lstGymService: [],
            lstGym: [],
        }
    },
    methods: {
        async getListGymUseService(service_id) {
            let url = '/gym/admin/gymservicebranch/service/' + service_id
            let params = {
                'status': constant.RECORD_ENABLE
            }
            const res = await get(url, params)
            if (res.code === 200) {
                return res.rows;
            }
            return []
        },
        async getListGym(system_id, service_id) {
            let url = '/gym/admin/gymbranch/findByGymId'
            let params = {
                'start': 0,
                'limit': 100,
                'gymId': system_id,
                'status': constant.RECORD_ENABLE,
            }
            const res = await get(url, params)
            this.lstGym = []
            if (res.code === 200) {
                this.lstGym = res.rows
                this.lstGymService = await this.getListGymUseService(service_id)
                this.lstGym.map(gym => {
                    let gymService = this.lstGymService.find(service => service.gymBranchId === gym.id)
                    gym.choose = false
                    if (gymService) {
                        gym.choose = true
                    }
                })
            }
        }
    },
    watch: {
        service: function (newVal, oldVal) {
            if (newVal) {
                this.getListGym(newVal.gymId, newVal.id)
            }
        }
    }
}
</script>