<template>
    <div>
        <Breadcrumbs title="Quản lý gói tập"/>
        <el-form
                ref="searchForm"
                style="width: 100%"
                :model="formSearch"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                label-position="left"
        >
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">Tên gói tập</label>
                    <el-form-item prop="key_search">
                        <el-input v-model="formSearch.key_search"/>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Loại gói tập</label>
                    <el-form-item prop="type">
                        <el-select clearable
                                   collapse-tags
                                   filterable
                                   v-model="formSearch.type" style="width:100%" placeholder="Chọn">
                            <el-option
                                    v-for="item in listTypePackage"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <el-form-item>
                        <el-button @click.prevent="onClickSearch" type="primary">
                            Tìm kiếm
                        </el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <div class="container-fluid">
            <div class="row justify-content-end mb-3">
                <el-button type="success" style="width:150px" @click="changeViewCreate">Thêm mới</el-button>
            </div>
        </div>
        <div class="row widget-grid">
            <div class="table-responsive">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="tableData.length==0 && show">
                    <p>Không tìm thấy kết quả phù hợp</p>
                    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <table class="table table-bordered table-hover" v-if="tableData.length>0">
                    <thead class="bg-primary">
                    <tr>
                        <th scope="col" width="50px">STT</th>
                        <th scope="col">Mã gói tập</th>
                        <th scope="col">Tên gói tập</th>
                        <th scope="col">Hệ thống phòng tập</th>
                        <th scope="col">Giá gói tập</th>
                        <th scope="col">Khung giờ</th>
                        <th scope="col">Thời lượng</th>
                        <th scope="col">Số lượt</th>
                        <th scope="col">Mô tả ngắn</th>
                        <th scope="col" style="width:13rem">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>{{ getIndex(index) }}</td>
                        <td>{{ item.package_code || item.id }}</td>
                        <td>{{ item.packageName }}</td>
                        <td>{{ item.gymName }}</td>
                        <td>{{ convertMoney(item.price) }}</td>
                        <td>
                            <p v-html="getTimeLine(item.timeLine)"></p>
                        </td>
                        <td>{{ getDuration(item.durationType, item.totalDuration) }}</td>
                        <td>{{ item.duration_time_2 || 0 }}</td>
                        <td>{{ item.shortDescription }}</td>
                        <td>
                            <button v-if="item.status ===1" @click.prevent="acceptPackage(item)"
                                    title="Duyệt hiển thị gói tập" type="button"
                                    class="btn btn-success mt-sm-2 me-2">
                                <i class="fa fa-check"></i>
                            </button>
                            <button data-bs-toggle="modal"
                                    data-bs-target="#modalDetailPackage" @click.prevent="showDetail(item)"
                                    title="Xem chi tiết gói tập" type="button"
                                    class="btn btn-primary mt-sm-2 me-2">
                                <i class="fa fa-eye"></i>
                            </button>
                            <button title="Sửa thông tin gói tập" @click.prevent="updatePackage(item)"
                                    class="btn btn-warning mt-sm-2 me-2" type="button">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button title="Xóa gói tập" @click="deletePackage(item)"
                                    class="btn btn-secondary mt-sm-2" type="button">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="pagging.totalPage>0" class="mt-3">
            <el-pagination
                    v-model:current-page="pagging.current_page"
                    v-model:page-size="pagging.size"
                    :page-sizes="[20, 50, 100]"
                    layout="total, sizes, prev, pager, next"
                    :total="pagging.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
        <div class="modal fade" id="modalDetailPackage" tabindex="-1" role="dialog"
             aria-labelledby="modalDetailPackage" aria-hidden="true">
            <ModalDetail :package="package_detail"/>
        </div>
    </div>
</template>
<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';
import ModalDetail from "@/pages/package/modal/modal_detail.vue";

export default {
    components: {ModalDetail},
    data() {
        return {
            package_detail: {},
            formSearch: {
                type: 1,
                key_search: ''
            },
            rules: {
                type: [
                    {required: true, message: 'Vui lòng chọn gói tập', trigger: 'change'}
                ],
            },
            listTypePackage: [
                {
                    value: 1,
                    name: 'Gói thường'
                },
                {
                    value: 2,
                    name: 'Gói khuyến mại'
                },
                {
                    value: 3,
                    name: 'Gói combo'
                },
            ],
            tableData: [],
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            callback: {
                view: '',
                package_update: {},
            },
            start: 0,
            limit: 20,
            show: false
        }
    },
    methods: {
        changeViewCreate() {
            this.callback.view = 2
            this.$emit("callback", this.callback);
        },
        onClickSearch() {
            this.start = 0;
            this.limit = 20;
            this.pagging.current_page = 1
            this.getDuLieu()
        },
        getDuLieu() {
            this.$refs['searchForm'].validate((valid) => {
                if (valid) {
                    if (this.formSearch.type === 1) {
                        this.getDuLieuGoiThuong()
                    } else {
                        this.getDuLieuGoiKhuyenMai()
                    }
                } else {
                    return false;
                }
            });
        },
        async getDuLieuGoiThuong() {
            let params = {
                'start': this.start,
                'limit': this.limit,
                'key': this.formSearch.key_search ? this.formSearch.key_search.trim() : '',
                'type': 0,
            }
            let url = '/gym/admin/gympackage'
            try {
                const res = await get(url, params)
                if (res.code === 200) {
                    if (res.rows.length > 0) {
                        this.tableData = res.rows
                        this.pagging.total = res.total
                        this.pagging.totalPage = res.total / this.pagging.size
                    } else {
                        this.tableData = [];
                        this.pagging.total = 0;
                        this.pagging.totalPage = 0;
                        this.show = true;
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.pagging.total = 0;
                    this.pagging.totalPage = 0;
                    this.show = true;
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.tableData = [];
                this.pagging.total = 0;
                this.pagging.totalPage = 0;
                this.show = true;
                this.$store.dispatch('setLoading', false);
            }
        },
        async getDuLieuGoiKhuyenMai() {
            const params = {
                start: this.start,
                limit: this.limit,
                'key': this.formSearch.key_search ? this.formSearch.key_search.trim() : '',
                'type': 0,
            }
            const url = '/gym/admin/event/getListPromotion'
            try {
                const res = await get(url, params)
                if (res.code === 200) {
                    if (res.rows.length > 0) {
                        this.tableData = res.rows
                        this.pagging.total = res.total
                        this.pagging.totalPage = res.total / this.pagging.size
                    } else {
                        this.tableData = [];
                        this.pagging.total = 0;
                        this.pagging.totalPage = 0;
                        this.show = true;
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.pagging.total = 0;
                    this.pagging.totalPage = 0;
                    this.show = true;
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.tableData = [];
                this.pagging.total = 0;
                this.pagging.totalPage = 0;
                this.show = true;
                this.$store.dispatch('setLoading', false);
            }
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },
        getIndex(index) {
            return (this.pagging.current_page - 1) * this.limit + index + 1
        },
        getDuration(type, val) {
            let durationType = parseInt(type)
            let totalTime = parseInt(val)
            if (durationType === 1) {
                return totalTime + ' tháng'
            } else {
                return totalTime + ' ngày'
            }
        },
        getTimeLine(timeline) {
            if (timeline === undefined) {
                return ''
            }
            let ret = ''
            if (timeline === 'FULL') {
                ret = 'Cả ngày'
            } else {
                let cc = timeline.split('|')
                if (this.isArray(cc)) {
                    for (let i = 0; i < cc.length; i++) {
                        if (i > 0) {
                            ret += ', '
                            ret += '<br/>'
                        }
                        ret += cc[i].replace(',', '-').trim()
                    }
                }
            }
            return ret
        },
        isArray(what) {
            return Object.prototype.toString.call(what) === '[object Array]';
        },
        deletePackage(item) {
            this.$swal({
                icon: 'question',
                text: 'Bạn có muốn xóa gói tập này?',
                showCancelButton: true,
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#e64942',
                cancelButtonText: 'Đóng',
                cancelButtonColor: '#efefef',
                reverseButtons: true,
                focusCancel: true,
            }).then(async (result) => {
                if (result.value) {
                    let url = '/gym/admin/gympackage/delete';
                    let params = {
                        id: item.id
                    }
                    const res = await post(url, params)
                    if (res.code === 200) {
                        this.$swal({
                            icon: 'success',
                            title: "Thông báo",
                            text: 'Xóa gói tập thành công',
                        });
                        this.onClickSearch()
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: "Thông báo",
                            text: 'Xóa gói tập thất bại',
                        });
                        this.onClickSearch()
                    }
                } else {
                    this.$swal.close()
                }
            });
        },
        async acceptPackage(item) {
            this.$swal({
                icon: 'question',
                text: 'Bạn có muốn duyệt gói tập này?',
                showCancelButton: true,
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#e64942',
                cancelButtonText: 'Đóng',
                cancelButtonColor: '#efefef',
                reverseButtons: true,
                focusCancel: true,
            }).then(async (result) => {
                if (result.value) {
                    let url = '/gym/admin/gympackage/confirm';
                    let params = {
                        id: item.id
                    }
                    const res = await post(url, params)
                    if (res.code === 200) {
                        this.$swal({
                            icon: 'success',
                            title: "Thông báo",
                            text: 'Duyệt gói tập thành công',
                        });
                        this.onClickSearch()
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: "Thông báo",
                            text: 'Duyệt gói tập thất bại',
                        });
                        this.onClickSearch()
                    }
                } else {
                    this.$swal.close()
                }
            });
        },
        showDetail(item) {
            this.package_detail = item
        },
        convertMoney(price) {
            if (price) {
                return price.toLocaleString('vi-VN') + ' VNĐ';
            }
            return ''
        },
        updatePackage(item) {
            this.callback.view = 3;
            this.callback.package_update = item
            this.$emit("callback", this.callback);
        }
    },
    mounted() {
        this.getDuLieuGoiThuong();
    },
    watch: {
        'formSearch.type': {
            handler(newVal, oldVal) {
                if (!newVal) {
                    this.tableData = []
                    this.show = true
                    this.pagging.total = 0
                    this.pagging.totalPage = 0
                } else {
                    this.onClickSearch()
                }
            },
        }
    }
}
</script>
<style scoped>

</style>