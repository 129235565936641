<template>
    <div>
        <Breadcrumbs main="Quản lý gói tập" title="Thêm mới gói tập"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="createForm"
                        :model="formCreate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100" v-if="isRoot()">
                        <label class="col-md-3 col-2 align-content-center f-16">Hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="system_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       v-model="formCreate.system_id" style="width:100%" placeholder="--Chọn--">
                                <el-option
                                        v-for="item in list_system"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mã gói tập <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="code" class="col-md-9 col-10">
                            <el-input v-model="formCreate.code"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên gói tập <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="name" class="col-md-9 col-10">
                            <el-input v-model="formCreate.name"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mô tả ngắn</label>
                        <el-form-item prop="short_desc" class="col-md-9 col-10">
                            <el-input v-model="formCreate.short_desc"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mô tả</label>
                        <el-form-item prop="long_desc" class="col-md-9 col-10">
                            <ClientOnly class="w-100">
                                <ckeditor :editor="editor" v-model="formCreate.long_desc"></ckeditor>
                            </ClientOnly>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tổng thời gian gói tập
                            <span class="font-danger">*</span>
                        </label>
                        <div class="col-md-9 col-10">
                            <el-form-item prop="total_month" style="width:100%;margin-bottom:8px">
                                <el-select clearable
                                           collapse-tags
                                           filterable
                                           :disabled="formCreate.total_type===0"
                                           v-model="formCreate.total_month" style="width:100%" placeholder="--Chọn--">
                                    <el-option
                                            v-for="item in list_package_time"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-checkbox v-model="formCreate.total_type" :true-label="0" :false-label="1"
                                         :value="formCreate.total_type" label="Hoặc" size="large" border/>
                            <div>
                                <span>Nhập số ngày bạn muốn:</span>
                                <el-form-item prop="total_day" style="width:100%">
                                    <el-input-number style="width:100%" :min="0" :disabled="formCreate.total_type===1"
                                                     v-model="formCreate.total_day"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Giá gói tập
                            <span class="font-danger">*</span>
                        </label>
                        <el-form-item prop="price" class="col-md-9 col-10">
                            <el-input
                                    :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                    :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
                                    v-model="formCreate.price">
                                <template #append>VND</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Khung thời gian áp dụng
                            <span class="font-danger">*</span>
                        </label>
                        <div class="col-md-9 col-10">
                            <el-checkbox v-model="formCreate.all_time" :true-label="1" :false-label="0"
                                         :value="formCreate.all_time" label="Cả ngày" size="large" border/>
                            <div>
                                <span>Hoặc</span>
                                <el-form-item prop="time_picker_application" class="w-100">
                                    <el-time-picker
                                            v-model="formCreate.time_picker_application"
                                            is-range
                                            :disabled="formCreate.all_time===1"
                                            value-format="HH:mm"
                                            format="HH:mm"
                                            :arrow-control="true"
                                            range-separator="Đến"
                                            start-placeholder="HH:MM"
                                            end-placeholder="HH:MM"
                                    />

                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Ngày áp dụng trong tuần
                            <span class="font-danger">*</span>
                        </label>
                        <el-form-item prop="day_of_week" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       multiple
                                       v-model="formCreate.day_of_week" style="width:100%" placeholder="--Chọn--">
                                <el-option
                                        v-for="item in list_package_day"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Thời gian cho phép đăng ký
                            <span class="font-danger">*</span>
                        </label>
                        <div class="col-md-9 col-10 row pe-0">
                            <div class="col-10">
                                <el-form-item prop="date_time_effect">
                                    <el-date-picker
                                            style="width:100%"
                                            v-model="formCreate.date_time_effect"
                                            type="daterange"
                                            clearable
                                            :disabled="formCreate.all_time_effect===1"
                                            value-format="YYYY-MM-DD HH:mm:ss"
                                            range-separator="Đến"
                                            start-placeholder="YYYY-MM-DD hh:ms:ss"
                                            end-placeholder="YYYY-MM-DD hh:ms:ss"
                                    />
                                </el-form-item>
                            </div>
                            <div class="col-2 pe-0">
                                <el-checkbox class="pull-right" v-model="formCreate.all_time_effect" :true-label="1"
                                             :false-label="0"
                                             :value="formCreate.all_time_effect" label="Mãi mãi" border/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <p class="fw-bold f-20 col-2">Các thông tin khác</p>
                        <diV class="col-10">
                            <hr class="border-3">
                        </diV>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Bảo lưu
                        </label>
                        <div class="col-md-9 col-10">
                            <div class="border border-2 row me-0 ms-0 pt-2">
                                <div class="col-4">
                                    <span>Số lần được bảo lưu</span>
                                    <el-form-item prop="numReserve" style="width:100%">
                                        <el-input-number v-model="formCreate.numReserve" min="0" class="w-100"/>
                                    </el-form-item>
                                </div>
                                <div class="col-4">
                                    <span>Số ngày bảo lưu tối đa</span>
                                    <el-form-item prop="maxNumReserve" style="width:100%">
                                        <el-input-number v-model="formCreate.maxNumReserve" min="0" class="w-100"/>
                                    </el-form-item>
                                </div>
                                <div class="col-4">
                                    <span>Số ngày còn lại của gói</span>
                                    <el-form-item prop="reserveDayValid" style="width:100%">
                                        <el-input-number v-model="formCreate.reserveDayValid" min="0" class="w-100"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 mt-3">
                        <label class="col-md-3 col-2 align-content-center f-16">Chuyển nhượng
                        </label>
                        <div class="col-md-9 col-10">
                            <div class="border border-2 row me-0 ms-0 pt-2">
                                <div class="col-6">
                                    <span>Số lần được chuyển nhượng</span>
                                    <el-form-item prop="numTransfer" style="width:100%">
                                        <el-input-number v-model="formCreate.numTransfer" min="0" class="w-100"/>
                                    </el-form-item>
                                </div>
                                <div class="col-6">
                                    <span>Số ngày còn lại của gói</span>
                                    <el-form-item prop="transferDayValid" style="width:100%">
                                        <el-input-number v-model="formCreate.transferDayValid" min="0" class="w-100"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 mt-3">
                        <label class="col-md-3 col-2 align-content-center f-16">Chiết khấu thêm
                        </label>
                        <el-form-item prop="allow_discount" class="col-md-9 col-10">
                            <el-switch
                                    v-model="formCreate.allow_discount"
                                    size="large"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="Có"
                                    inactive-text="Không"
                            />
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Thêm mới
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import constant, {Secret_Key} from "@/constants/config";
import CryptoJS from "crypto-js";
import {get, post} from "@/m_utils/rest_api";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    components: {
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editor: ClassicEditor,
            formCreate: {
                system_id: '',
                code: '',
                name: '',
                short_desc: '',
                long_desc: '',
                total_month: '',
                total_type: 0,
                total_day: '',
                price: 0,
                all_time: 0,
                time_picker_application: '',
                day_of_week: [],
                all_time_effect: 0,
                date_time_effect: [],
                numReserve: 0,
                maxNumReserve: 0,
                reserveDayValid: 0,
                numTransfer: 0,
                transferDayValid: 0,
                allow_discount: 0
            },
            rules: {
                system_id: [
                    {required: true, message: 'Vui lòng chọn hệ thống phòng tập', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: 'Vui lòng nhập mã gói tập', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: 'Vui lòng nhập tên gói tập', trigger: 'blur'}
                ],
                day_of_week: [
                    {required: true, message: 'Vui lòng chọn ngày tập trong tuần', trigger: 'blur'}
                ],

                total_month: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.formCreate.total_type === 1 && !value) {
                                callback(new Error('Vui lòng chọn tổng thời gian gói tập'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                total_day: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.formCreate.total_type === 0 && value === 0) {
                                callback(new Error('Vui lòng chọn tổng thời gian gói tập'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                price: [
                    {required: true, message: 'Vui lòng nhập giá gói tập', trigger: 'blur'},
                    {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error('Vui lòng nhập giá gói tập'));
                            } else if (isNaN(value)) {
                                callback(new Error('Giá gói tập phải là số hợp lệ'));
                            } else if (value <= 0) {
                                callback(new Error('Giá gói tập phải lớn hơn 0'));
                            } else {
                                callback(); // Xác thực thành công
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                time_picker_application: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.formCreate.all_time === 0 && (!value ||( value && value.length===0))) {
                                callback(new Error('Vui lòng chọn thời gian áp dụng cho gói tập'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                date_time_effect: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.formCreate.all_time_effect === 0 && (!value ||( value && value.length===0))) {
                                callback(new Error('Vui lòng chọn thời gian đăng ký gói tập'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ]
            },
            role: '',
            user: {},
            list_system: [],
            list_package_time: [],
            list_package_day: [],
            callback: {
                view: 1
            },
        }
    },
    methods: {
        clickBack() {
            this.$emit("callback", this.callback);
        },
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        getUserAndRole() {
            let user = this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            if (this.role === constant.SUPER_ROOT) {
                this.getListSystem()
            } else {
                this.formCreate.system_id = decryptedUser.gym_id
            }
            this.user = decryptedUser
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_system=res.rows;
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();

                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system.push(...res.rows);
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('createForm');
        },
        resetForm(createForm) {
            this.$refs[createForm].resetFields();
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('createForm')
        },
        onSubmit(createForm) {
            if (!this.isRoot()) {
                this.formCreate.system_id = this.user.gym_id
            }
            this.$refs[createForm].validate((valid) => {
                if (valid) {
                    this.onCreate()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async onCreate(){
            let url ='/gym/admin/gympackage'
            let params= {
                gym_id: this.formCreate.system_id,
                package_name: this.formCreate.name,
                package_code: this.formCreate.code,
                short_description: this.formCreate.short_desc,
                long_description: this.formCreate.long_desc,
                price: this.formCreate.price,
                type:0,
                gym_branch_ids: this.user.branch_id||'',
                numReserve: this.formCreate.numReserve,
                maxNumReserve: this.formCreate.maxNumReserve,
                reserveDayValid: this.formCreate.reserveDayValid,
                numTransfer: this.formCreate.numTransfer,
                transferDayValid: this.formCreate.transferDayValid,
                statusDiscount: this.formCreate.allow_discount,
                days_of_week: this.formCreate.day_of_week.join(',')
            }
            if(this.formCreate.all_time){
                params.time_line='FULL'
            }
            else {
                params.time_line= this.formCreate.time_picker_application.join(',')
            }
            if(this.formCreate.all_time_effect){
                let now = new Date();
                params.start_time_effect = now.toISOString().slice(0, 23);
                const endDateString = '2099-12-31 23:59:59';
                const endDate = new Date(endDateString.replace(' ', 'T') + '.000');
                params.end_time_effect = endDate.toISOString().slice(0, 23);
            }
            else {
                params.start_time_effect = new Date(this.formCreate.date_time_effect[0].replace(' ', 'T') + '.000').toISOString().slice(0, 23);
                params.end_time_effect = new Date(this.formCreate.date_time_effect[1].replace(' ', 'T') + '.000').toISOString().slice(0, 23);
            }
            params.duration_type = this.formCreate.total_type
            if(this.formCreate.total_type)
            {
                params.total_duration = this.formCreate.total_month
            }
            else {
                params.total_duration = this.formCreate.total_day
            }
            const res = await post(url, params)
            if (res.code === 200) {
                this.$swal({
                    icon: 'success',
                    title: "Thông báo",
                    text: 'Tạo gói tập thành công',
                }).then((result) => {
                    if (result.value) {
                        this.$refs['createForm'].resetFields();
                        this.clickBack()
                    } else {
                        this.$refs['createForm'].resetFields();
                        this.clickBack()
                    }
                });
            } else {
                this.$swal({
                    icon: 'error',
                    title: "Thông báo",
                    text: res.message || 'Tạo gói tập thất bại',
                })
            }
        }
    },
    watch: {
        'formCreate.total_type': function (val) {
            if (val) {
                this.formCreate.total_month = ''
            } else {
                this.formCreate.total_day = 0
            }
        },
        'formCreate.all_time': function (val) {
            if (val) {
                this.formCreate.time_picker_application = [];
            }
        },
        'formCreate.day_of_week': function (val) {
            if (val.includes(9999)) {
                this.formCreate.day_of_week = []
                this.formCreate.day_of_week = constant.PACKAGE_DAY_OF_WEEK.map(item => item.value);
            }
        },
        'formCreate.all_time_effect': function (val) {
            if (val) {
                this.formCreate.date_time_effect = []
            }
        }
    },
    beforeMount() {
        this.getUserAndRole();
        this.list_package_time = constant.PACKAGE_TOTAL_TIME
        let all = [{
            value: 9999,
            name: 'Tất cả'
        }]
        this.list_package_day = all
        this.list_package_day.push(...constant.PACKAGE_DAY_OF_WEEK)
    }
}
</script>
<style scoped>

</style>