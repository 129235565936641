<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalDetailUser">Chi tiết hệ thống: {{ system.name }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body row">
                <div class="col-3 col-md-4">
                    <img v-if="system.imageUrl"
                         class="b-r-10"
                         :src="system.imageUrl"
                         alt=""
                         width="100%"/>
                </div>
                <div class="col-9 col-md-8">
                    <table class="table table-responsive-md">
                        <tbody>
                        <tr>
                            <td>Tên hệ thống:</td>
                            <td>{{ system.name }}</td>
                        </tr>
                        <tr>
                            <td>Địa chỉ trụ sở:</td>
                            <td class="text-break">{{ system.headQuaterAddress }}</td>
                        </tr>
                        <tr>
                            <td>Số điện thoại:</td>
                            <td>{{ system.headQuaterPhone }}</td>
                        </tr>
                        <tr>
                            <td>Website:</td>
                            <td class="text-break"><a :href="system.website" target="_blank">{{ system.website }}</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Facebook:</td>
                            <td class="text-break"><a :href="getUrlFacebook(system.facebook)"
                                                      target="_blank">{{ system.facebook }}</a></td>
                        </tr>
                        <tr>
                            <td>Ngày tạo:</td>
                            <td>{{ convertDate(system.createdTime) }}</td>
                        </tr>
                        <tr>
                            <td>Ngày cập nhật:</td>
                            <td>{{ convertDate(system.updatedTime) }}</td>
                        </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import time_utils from '/src/m_utils/time_utils'

export default {
    props: ["system"],
    methods: {
        convertDate(date) {
            return time_utils.convertDate(date);
        },
        getUrlFacebook(url) {
            if (!url) {
                return ''
            }
            let isValid = url.startsWith('https://') || url.startsWith('http://');
            if (isValid) {
                return url
            } else {
                let url_return = 'https://' + url
                return url_return
            }
        }
    }
}
</script>