<template>
    <div>
        <list-service v-if="view===1" @callback="callBack($event)"></list-service>
        <create-service v-if="view===2" @callback="callBack($event)"></create-service>
        <update-service v-if="view===3" @callback="callBack($event)" :service_update="service_update"></update-service>

    </div>
</template>

<script>
import ListService from "@/pages/service/list_service.vue";
import CreateService from "@/pages/service/create_service.vue";
import UpdateService from "@/pages/service/update_service.vue";


export default {
    components: {
        'listService': ListService,
        'createService': CreateService,
        'updateService': UpdateService,
    },
    data() {
        return {
            view: 1,
            service_update: {},
        };
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.service_update = step.service_update ? step.service_update : {}
        },
    },
};
</script>

