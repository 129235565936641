<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Cập nhật cấu hình phòng tập: {{ gym.name }}</h5>
                <button class="btn-close" id="close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <el-form
                        label-position="left"
                        ref="configForm"
                        :model="formConfig"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100">
                        <label class="col-md-6 col-4 align-content-center f-16">Số lần check in không cần duyệt</label>
                        <el-form-item prop="check_in_free_per_day" class="col-md-6 col-8">
                            <el-input-number min="0" class="w-100" v-model="formConfig.check_in_free_per_day"
                                             placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-6 col-4 align-content-center f-16">Số lần nợ đồ</label>
                        <el-form-item prop="amount_lend_allow" class="col-md-6 col-8">
                            <el-input-number min="0" class="w-100" v-model="formConfig.amount_lend_allow"
                                             placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-6 col-4 align-content-center f-16">Số hình thức check in</label>
                        <el-form-item prop="num_check_in_allow" class="col-md-6 col-8">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       placeholder="--Chọn--"
                                       v-model="formConfig.num_check_in_allow" style="width:100%">
                                <el-option
                                        v-for="item in listCheckIn"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-6 col-4 align-content-center f-16">Khóa thiết bị check in</label>
                        <el-form-item prop="lock_device_check_in" class="col-md-6 col-8">
                            <el-switch
                                    v-model="formConfig.lock_device_check_in"
                                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                                    :active-value=1
                                    :inactive-value=0
                            />
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-6 col-4 align-content-center f-16">Check in không cần duyệt check
                            out</label>
                        <el-form-item prop="check_in_style" class="col-md-6 col-8">
                            <el-switch
                                    v-model="formConfig.check_in_style"
                                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                                    :active-value=1
                                    :inactive-value=0
                            />
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-6 col-4 align-content-center f-16">Nhập nhanh mã vân tay hội viên</label>
                        <el-form-item prop="inputable_finger" class="col-md-6 col-8">
                            <el-switch
                                    v-model="formConfig.inputable_finger"
                                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                                    :active-value=1
                                    :inactive-value=0
                            />
                        </el-form-item>
                    </div>

                </el-form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-bs-dismiss="modal">Đóng</button>
                <button class="btn btn-primary" @click="handleSubmit">Cập nhật</button>
            </div>
        </div>
    </div>
</template>
<script>
import {post} from '/src/m_utils/rest_api'
import $ from "jquery";

export default {
    props: ["gym"],
    data() {
        return {
            formConfig: {
                check_in_free_per_day: 0,
                amount_lend_allow: 0,
                num_check_in_allow: -1,
                lock_device_check_in: 0,
                check_in_style: 0,
                inputable_finger: 0,
            },
            rules: {
                check_in_free_per_day: [
                    {required: true, message: 'Vui lòng nhập số lần check in không cần duyệt', trigger: 'change'},
                    {
                        type: 'number', min: 0,
                        message: 'Số lần check in không cần duyệt cần lớn hơn hoặc bằng 0', trigger: 'change'
                    },
                ],
                amount_lend_allow: [
                    {required: true, message: 'Vui lòng nhập số lần nợ đồ', trigger: 'change'},
                    {type: 'number', min: 0, message: 'Số lần nợ đồ cần lớn hơn hoặc bằng 0', trigger: 'change'},
                ],
            },
            listCheckIn: [
                {
                    'name': 'Tất cả',
                    'value': -1
                },
                {
                    'name': '1 hình thức',
                    'value': 1
                },
                {
                    'name': '2 hình thức',
                    'value': 2
                },
                {
                    'name': '3 hình thức',
                    'value': 3
                },
                {
                    'name': '4 hình thức',
                    'value': 4
                },
                {
                    'name': '5 hình thức',
                    'value': 5
                },
                {
                    'name': '6 hình thức',
                    'value': 6
                },

            ],
        }
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('configForm')
        },
        onSubmit(configForm) {
            this.$refs[configForm].validate((valid) => {
                if (valid) {
                    this.onUpdate()
                } else {
                    return false;
                }
            });
        },
        async onUpdate() {
            let url = '/gym/admin/gymbranch/update-branch-config';
            let params = {
                'id': this.gym.id,
                'check_in_free_per_day': this.formConfig.check_in_free_per_day,
                "amount_lend_allow": this.formConfig.amount_lend_allow,
                "num_check_in_allow": this.formConfig.num_check_in_allow,
                "lock_device_check_in": this.formConfig.lock_device_check_in,
                "check_in_style": this.formConfig.check_in_style,
                "inputable_finger": this.formConfig.inputable_finger,
            }
            const res = await post(url, params)
            if (res.code === 200) {
                this.$swal({
                    icon: 'success',
                    title: "Thông báo",
                    text: 'Cập nhật cấu hình phòng tập thành công',
                }).then((result) => {
                    if (result.value) {
                        $("#close").click()
                    } else {
                        $("#close").click()
                    }
                });
            } else {
                this.$swal({
                    icon: 'error',
                    title: "Thông báo",
                    text: res.message || 'Cập nhật cấu hình phòng tập thất bại',
                })
            }
        }
    },
    watch: {
        gym: function (newVal, oldVal) {
            if (newVal) {
                this.formConfig.amount_lend_allow = newVal.amount_lend_allow || 0;
                this.formConfig.check_in_free_per_day = newVal.check_in_free_per_day || 0;
                this.formConfig.check_in_style = newVal.check_in_style || 0;
                this.formConfig.inputable_finger = newVal.inputable_finger || 0;
                this.formConfig.lock_device_check_in = newVal.lock_device_check_in || 0;
                this.formConfig.num_check_in_allow = newVal.num_check_in_allow || -1;
            }
        }
    }

}
</script>