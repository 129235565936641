export const defaultLocale = 'en';
export const localeOptions = [
    {id: 'en', name: 'English', icon: 'flag-icon-us', short: '(US)'},
];
export const BASE_URL_API = 'https://vfit-web-api.dtsgroup.com.vn'
export const Secret_Key = 'vfit-2024';
export const API_GOOGLE_MAP_KEY = 'AIzaSyCxFFoao8aHIbDwMxJJ8W4lMk_KiXIwig8';
export default {
    PACKAGE_TOTAL_TIME: [
        {
            "value": 1,
            "name": "1 Tháng"
        },
        {
            "value": 2,
            "name": "2 Tháng"
        },
        {
            "value": 3,
            "name": "3 Tháng"
        },
        {
            "value": 4,
            "name": "4 Tháng"
        },
        {
            "value": 5,
            "name": "5 Tháng"
        },
        {
            "value": 6,
            "name": "6 Tháng"
        },
        {
            "value": 7,
            "name": "7 Tháng"
        },
        {
            "value": 8,
            "name": "8 Tháng"
        },
        {
            "value": 9,
            "name": "9 Tháng"
        },
        {
            "value": 10,
            "name": "10 Tháng"
        },
        {
            "value": 11,
            "name": "11 Tháng"
        },
        {
            "value": 12,
            "name": "12 Tháng"
        },
        {
            "value": 16,
            "name": "16 Tháng",
        },
        {
            "value": 24,
            "name": "2 Năm",
        },
        {
            "value": 36,
            "name": "3 Năm",
        },
        {
            "value": 60,
            "name": "5 Năm",
        },
    ],
    PACKAGE_DAY_OF_WEEK:[
        {
            value:'MON',
            name:'Thứ Hai',
        },
        {
            value:'TUE',
            name:'Thứ Ba',
        },
        {
            value:'WED',
            name:'Thứ Tư',
        },
        {
            value:'THU',
            name:'Thứ Năm',
        },
        {
            value:'FRI',
            name:'Thứ Sáu',
        },
        {
            value:'SAT',
            name:'Thứ Bảy',
        },
        {
            value:'SUN',
            name:'Chủ Nhật',
        },

    ],

    RECORD_ENABLE: 3,
    RECORD_DISABLE: 2,
    // contract status
    CONTRACT_STATUS_NORMAL: 0,
    CONTRACT_STATUS_RENEWALED: 1,
    CONTRACT_STATUS_RESERVE: 2, // Bao luu
    CONTRACT_STATUS_TRANSFERED: 3,
    CONTRACT_STATUS_BLOCKED: 4,

    // sub status of contract
    SUB_STATUS_NORMAL: 0,
    SUB_STATUS_RESERVE: 1, // Dang cho bao luu
    SUB_STATUS_TRANSFER: 2, // Dang cho chuyen nhuong

    // server role
    SUPER_ROOT: 1, //superAdmin
    GYM_SYSTEM: 2, //admin he thong
    GYM_BRANCH_GROUP: 3,
    GYM_BRANCH: 4,

    //branch_acc_type
    GYM_BRANCH_LT: 1,
    GYM_BRANCH_PT: 2,
    GYM_BRANCH_SALE: 3,
    GYM_BRANCH_KS: 3,

    TYPE_CHECK_IN: 0,
    TYPE_CHECK_OUT: 1,

    TYPE_CHECK_IN_APP: 1,
    TYPE_CHECK_IN_CARD: 2,
    TYPE_CHECK_IN_FINGERPRINT: 3,

    STATUS_CHECKIN_REQUEST: 0,
    STATUS_CHECKIN_CONFIRM: 1,
    STATUS_CHECKOUT_REQUEST: 2,
    STATUS_CHECKOUT_CONFIRM: 3,
    STATUS_CHECKOUT_CANCEL: 4,
    STATUS_CHECKIN_CANCEL: 5,

    PROMOTION_TYPE_VALUE: 0,
    PROMOTION_TYPE_PERCENT: 1,
    PROMOTION_TYPE_DAY: 2,
    PROMOTION_TYPE_MONTH: 3,

    PROMOTION_SOURCE_PRICE: 0,
    PROMOTION_SOURCE_TIME_DAY: 1,
    PROMOTION_SOURCE_PT: 3,

    TYPE_NTF_NEW_EXERCISE: 1,
    TYPE_NTF_NEW_PACKAGE: 2,
    TYPE_NTF_NEWS: 3,
    TYPE_NTF_QA_QUESTION: 4,
    TYPE_NTF_ALARM: 5,
    TYPE_NTF_UPGRADE_APP: 6,
    TYPE_NTF_NEW_PROMOTION: 7,

    NTF_LEVEL_ALL: 0,
    NTF_LEVEL_GROUP_GYM: 1,
    NTF_LEVEL_GYM: 2,
    NTF_LEVEL_GROUP_BRANCH: 3,
    NTF_LEVEL_BRANCH: 4,
    NTF_LEVEL_GROUP_USER: 5,
    NTF_LEVEL_USER: 6,

    CHECKIN_FROM_APP_NORMAL: 0,
    CHECKIN_FROM_APP_QR_CODE: 1,
    CHECKIN_FROM_WEB_BY_ADMIN: 2,
    CHECKIN_FROM_FINGER_SPRINT: 3,
    CHECKIN_FROM_CARD: 4,

    PT_PACKET_NOT_ACCEPT: 1,
    PT_PACKET_DISABLE: 2,
    PT_PACKET_ACCEPT: 3,
    PT_PACKET_LOCK: 4,

    PT_PACKET_NOT_ACCEPT_MES: 'Chưa duyệt',
    PT_PACKET_DISABLE_MES: 'Đã Xóa',
    PT_PACKET_ACCEPT_MES: 'Đã duyệt',
    PT_PACKET_LOCK_MES: 'Đã bị khóa',

    PT_CONTRACT_NOT_ACCEPT: 1,
    PT_CONTRACT_DISABLE: 2,
    PT_CONTRACT_ACCEPT: 3,
    PT_CONTRACT_LOCK: 4,

    PT_CONTRACT_NOT_ACCEPT_MES: 'Chưa duyệt',
    PT_CONTRACT_DISABLE_MES: 'Đã Xóa',
    PT_CONTRACT_ACCEPT_MES: 'Đã duyệt',
    PT_PCONTRACT_LOCK_MES: 'Đã bị khóa',

    USER_GENDER_MALE: 'male',
    USER_GENDER_FEMALE: 'female ',

    USER_GENDER_MALE_SHOW: 'Nam',
    USER_GENDER_FEMALE_SHOW: 'Nữ',

    PT_CONTRACT_IS_PAID: 1,
    PT_CONTRACT_NOT_PAID: 0,
    PT_CONTRACT_IS_PAID_MES: 'Đã thanh toán',
    PT_CONTRACT_NOT_PAID_MES: 'Chưa thanh toán',

    PT_CONTRACT_PERCENT: 1,
    PT_CONTRACT_VND: 2,
    PT_CONTRACT_PERCENT_DISPLAY: '%',
    PT_CONTRACT_VND_DISPLAY: 'VNĐ',

    PREFIX_MEMBER_CODE: 'DH',

    TRAN_STATUS_DEPOSIT: 1, //Đặt cọc
    TRAN_STATUS_FULL: 2, //Thanh toán đủ ngay từ đầu
    TRAN_STATUS_AFTER_DEPOSIT: 3, // Thanh toán số tiền còn lại sau khi đặt cọc
    TRAN_STATUS_RENEWAL: 4 // Thanh toán sau khi đã gia hạn
}
