<template>
    <div class="page-wrapper compact-wrapper" id="pageWrapper">

        <div class="error-wrapper">
            <div class="container"><img class="img-100" src="@/assets/images/other-images/sad.png" alt="">
                <div class="error-heading">
                    <h2 class="headline font-danger">404</h2>
                </div>
                <div class="col-md-8 offset-md-2">
                    <p class="sub-content">Trang không tồn tại <br/>Xin lỗi, nhưng trang bạn đang tìm kiếm không tồn tại</p>
                </div>
                <div>
                    <router-link class="btn btn-danger-gradien btn-lg" to="/">Trở về trang chủ</router-link>
                </div>
            </div>
        </div>

    </div>
</template>