<template>
    <div>
        <Breadcrumbs main="Quản lý dịch vụ" title="Cập nhật dịch vụ"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="updateForm"
                        :model="formUpdate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100" v-if="isRoot()">
                        <label class="col-md-3 col-2 align-content-center f-16">Hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="system_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       @change="changeSystemID"
                                       v-model="formUpdate.system_id" style="width:100%" placeholder="Chọn">
                                <el-option
                                        v-for="item in list_system"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Phòng tập<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="gym_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       :disabled="formUpdate.system_id ? false: true"
                                       no-data-text="Không tìm thấy phòng tập nào"
                                       :multiple="true"
                                       v-model="formUpdate.gym_id" style="width:100%" placeholder="Chọn">
                                <el-option
                                        v-for="item in list_gym"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên dịch vụ <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="name" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.name" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Loại dịch vụ</label>
                        <el-form-item prop="type" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       placeholder="--Chọn--"
                                       v-model="formUpdate.type" style="width:100%">
                                <el-option
                                        v-for="item in listType"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Cập nhật
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import constant, {Secret_Key} from "@/constants/config";
import CryptoJS from "crypto-js";
import {get} from "@/m_utils/rest_api";

export default {
    props: ['service_update'],
    data() {
        return {
            callback: {
                view: 1
            },
            list_system: [],
            listType: [
                {
                    'name': 'Đồ cho mượn',
                    'value': 1
                },
                {
                    'name': 'Thiết bị phòng tập',
                    'value': 2
                },
            ],
            formUpdate: {
                system_id: '',
                gym_id: '',
                name: '',
                type: 1,
            },
            rules: {
                name: [
                    {required: true, message: 'Vui lòng nhập tên phòng tập', trigger: 'change'},
                ],
                system_id: [
                    {required: this.isRoot(), message: 'Vui lòng chọn hệ thống', trigger: 'change'},
                ],
                gym_id: [
                    {required: true, message: 'Vui lòng chọn phòng tập', trigger: 'blur '},
                ],
            },
            role: '',
            user: '',
            list_gym: [],
        }
    },
    methods: {
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        clickBack() {
            this.$emit("callback", this.callback);
        },
        getUserAndRole() {
            let user =  this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_system = res.rows;
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system = res.rows
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        async changeSystemID() {
            this.formUpdate.gym_id = '';
            this.list_gym = await this.getListGym(this.formUpdate.system_id)
        },
        async getListGym(system_id) {
            try {
                this.$store.dispatch('setLoading', true);
                let request_params = {
                    gymId: system_id,
                }
                let url = 'gym/gymbranch/getByGymId'
                const res = await get(url, request_params)
                if (res.code === 200) {
                    this.$store.dispatch('setLoading', false);
                    return res.rows
                } else {
                    this.$store.dispatch('setLoading', false);
                    return []
                }
            } catch (e) {
                this.$store.dispatch('setLoading', false);
                return []
            }
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('updateForm')
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('updateForm');
        },
        resetForm(updateForm) {
            this.$refs[updateForm].resetFields();
        },
        onSubmit(updateForm) {
            this.$refs[updateForm].validate((valid) => {
                if (valid) {
                    this.onCreate()
                } else {
                    return false;
                }
            });
        },
        async onCreate() {
            alert('đang đợi API hihi')
        },
        async setValueForm(service_update) {
            console.log(service_update)
            this.formUpdate.system_id = service_update.gymId
            if (this.role > constant.SUPER_ROOT) {
                this.list_gym = await this.getListGym(this.user.gym_id)
            } else {
                this.list_gym = await this.getListGym(this.formUpdate.system_id)
            }
            this.formUpdate.name = service_update.name
            this.formUpdate.type = service_update.type
        }
    },
    beforeMount() {
        this.getUserAndRole()
        this.getListSystem()
        this.setValueForm(this.service_update)
    },

}
</script>
<style>

</style>