export const overallbalance = [{
    iconName: "income",
    title: "Income",
    amount: "$22,678",
    balance: "+$456",
    balanceClass: "font-success"
},
    {
        iconName: "expense",
        title: "Expense",
        amount: "$12,057",
        balance: "+$456",
        balanceClass: "font-danger"
    },
    {
        iconName: "doller-return",
        title: "Cashback",
        amount: "$8475",
    }
]

export const activities = [{
    date: "8th March, 2022",
    day: "1 day ago",
    title: "Updated Product",
    decription: "I don't like things to be staged or fussy.",
    class: "activity-dot-primary"

}, {
    date: "15th Oct, 2022",
    day: "Today",
    title: "Tello just like your product",
    decription: "If you have it, you can make anything look good.",
    class: "activity-dot-warning"

},
    {
        date: "20th Sep, 2022 ",
        day: "12:00 PM",
        title: " Tello just like your product",
        decription: "I like to design everything to do with the body.",
        class: "activity-dot-secondary"

    }
]

export const resentSales = [{
    image: require("@/assets/images/dashboard/user/1.jpg"),
    name: "Jane Cooper",
    time: "10 minutes ago",
    amount: "$200.00",
},
    {
        image: require("@/assets/images/dashboard/user/2.jpg"),
        name: "	Brooklyn Simmons",
        time: "19 minutes ago",
        amount: "$970.00",
    },
    {
        image: require("@/assets/images/dashboard/user/3.jpg"),
        name: "Leslie Alexander",
        time: "2 hours ago",
        amount: "$300.00",
    },
    {
        image: require("@/assets/images/dashboard/user/4.jpg"),
        name: "Travis Wright",
        time: "8 hours ago",
        amount: "$450.00",
    },
    {
        image: require("@/assets/images/dashboard/user/5.jpg"),
        name: "Mark Green",
        time: "1 day ago",
        amount: "$768.00",
    },
]

export const paperNotes = [{
    label: "SAAS",
    labelClass: "badge badge-light-primary",
}, {
    label: "E-Commerce",
    labelClass: "badge badge-light-success",
}, {
    label: "Crypto",
    labelClass: "badge badge-light-warning",
}, {
    label: "Project",
    labelClass: "badge badge-light-info",
}, {
    label: "NFT",
    labelClass: "badge badge-light-secondary",
}, {
    label: "+9",
    labelClass: "badge badge-light-light",
},]

export const customers = [{
    image: require("@/assets/images/dashboard/user/1.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/6.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/7.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/3.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/8.jpg"),
},]

export const puechaseSalesDetails1 = [{
    widgetClass: "widget-round secondary",
    svgIcon1: "cart",
    svgIcon2: "halfcircle",
    number: "10,000",
    title: "Purchase",
    growthClass: "font-secondary f-w-500",
    iconClass: "icon-arrow-up icon-rotate me-1",
    growthNumber: "+50%"

},
    {
        widgetClass: "widget-round primary",
        svgIcon1: "tag",
        svgIcon2: "halfcircle",
        number: "4,200",
        title: "Sales",
        growthClass: "font-primary f-w-500",
        iconClass: "icon-arrow-up icon-rotate me-1",
        growthNumber: "+70%",

    }
]
export const puechaseSalesDetails2 = [{
    widgetClass: "widget-round warning",
    svgIcon1: "return-box",
    svgIcon2: "halfcircle",
    number: "7000",
    title: "Sales return",
    growthClass: "font-warning f-w-500",
    iconClass: "icon-arrow-down icon-rotate me-1",
    growthNumber: "-20%"

},
    {
        widgetClass: "widget-round success",
        svgIcon1: "rate",
        svgIcon2: "halfcircle",
        number: "5700",
        title: "Purchase rate",
        growthClass: "font-success f-w-500",
        iconClass: "icon-arrow-up icon-rotate me-1",
        growthNumber: "+70%",

    }
]