<template>
    <div>
        <Breadcrumbs title="Quản lý hệ thống"/>
        <el-form
                ref="searchForm"
                style="width: 100%"
                :model="searchForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                label-position="left"
        >
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">Tên hệ thống</label>
                    <el-form-item prop="name">
                        <el-input v-model="searchForm.name"/>
                    </el-form-item>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <el-form-item>
                        <el-button @click="onClickSearch" type="primary">
                            Tìm kiếm
                        </el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>

        <div class="container-fluid">

            <div class="row justify-content-end mb-3">
                <el-button type="success" style="width:150px" @click="changeViewCreate">Thêm mới</el-button>
            </div>
        </div>
        <div class="row widget-grid">
            <div class="table-responsive">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="tableData.length==0 && show">
                    <p>Không tìm thấy kết quả phù hợp</p>
                    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <table class="table table-bordered table-hover" v-if="tableData.length>0">
                    <thead class="bg-primary">
                    <tr>
                        <th scope="col" width="50px">STT</th>
                        <th scope="col">Tên hệ thống</th>
                        <th scope="col">Ảnh đại diện</th>
                        <th scope="col">Địa chỉ trụ sở</th>
                        <th scope="col">Số điện thoại</th>
                        <th scope="col">Thao tác</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>{{ getIndex(index) }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                            <img v-if="item.imageUrl"
                                 class="b-r-10"
                                 :src="item.imageUrl"
                                 alt=""
                                 height="100px"/>
                        </td>
                        <td class="text-break">{{ item.headQuaterAddress }}</td>
                        <td>{{ item.headQuaterPhone }}</td>
                        <td>
                            <button @click="showDetail(item)" data-bs-toggle="modal"
                                    data-bs-target="#modalDetailUser" title="Xem thông tin hệ thống"
                                    class="btn btn-primary mt-sm-2 me-2">
                                <i class="fa fa-eye"></i>
                            </button>
                            <button title="Sửa thông tin hệ thống" @click="onUpdateSystem(item)"
                                    class="btn btn-warning mt-sm-2 me-2" type="button">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button title="Xóa hệ thống" @click="deleteSystem(item)"
                                    class="btn btn-secondary mt-sm-2" type="button">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>

                </table>

            </div>
        </div>
        <div v-if="pagging.totalPage>0" class="mt-3">
            <el-pagination
                    v-model:current-page="pagging.current_page"
                    v-model:page-size="pagging.size"
                    :page-sizes="[20, 50, 100]"
                    layout="total, sizes, prev, pager, next"
                    :total="pagging.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
        <div class="modal fade" id="modalDetailUser" tabindex="-1" role="dialog"
             aria-labelledby="modalDetailUser" aria-hidden="true">
            <ModalDetail :system="system_detail"/>
        </div>
    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';
import constant, {Secret_Key} from '/src/constants/config'
import ModalDetail from "@/pages/system/modal/modal_detail";
import CryptoJS from "crypto-js";

export default {
    components: {
        'ModalDetail': ModalDetail,
    },
    data() {
        return {
            searchForm: {
                name: '',
            },
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            callback: {
                view: '',
                update_system: {},
            },
            show: false,
            start: 0,
            limit: 20,
            rules: {},
            tableData: [],
            system_detail: {},
            role: '',
            user: {},
        };
    },
    methods: {
        showDetail(system) {
            this.system_detail = system
        },
        deleteSystem(item) {
            this.$swal({
                icon: 'question',
                text: 'Bạn có muốn xóa hệ thống này?',
                showCancelButton: true,
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#e64942',
                cancelButtonText: 'Đóng',
                cancelButtonColor: '#efefef',
                reverseButtons: true,
                focusCancel: true,
            }).then(async (result) => {
                if (result.value) {
                    let url = '/gym/admin/gym/' + item.id;
                    const res = await remove(url)
                    if (res.code === 200) {
                        this.$swal({
                            icon: 'success',
                            title: "Thông báo",
                            text: 'Xóa hệ thống thành công',
                        });
                        this.onClickSearch()
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: "Thông báo",
                            text: 'Xóa hệ thống thất bại',
                        });
                        this.onClickSearch()
                    }
                } else {
                    this.$swal.close()
                }
            });
        },
        onClickSearch() {
            this.start = 0;
            this.limit = 20;
            this.pagging.current_page = 1
            this.getDuLieu()
        },
        changeViewCreate() {
            this.callback.view = 2
            this.$emit("callback", this.callback);
        },
        getIndex(index) {
            return (this.pagging.current_page - 1) * this.limit + index + 1
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },

        async getDuLieu() {
            this.$store.dispatch('setLoading', true);
            let params = {
                'start': this.start,
                'limit': this.limit,
                'status': constant.RECORD_ENABLE,
                'key': this.searchForm.name ? this.searchForm.name.trim() : ''
            }
            let url = '/gym/admin/gym'
            try {
                const res = await get(url, params)
                if (res.code === 200) {
                    if (res.rows.length > 0) {
                        this.tableData = res.rows
                        this.pagging.total = res.total
                        this.pagging.totalPage = res.total / this.pagging.size
                    } else {
                        this.tableData = [];
                        this.pagging.total = 0;
                        this.pagging.totalPage = 0;
                        this.show = true;
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.pagging.total = 0;
                    this.pagging.totalPage = 0;
                    this.show = true;
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.tableData = [];
                this.pagging.total = 0;
                this.pagging.totalPage = 0;
                this.show = true;
                this.$store.dispatch('setLoading', false);
            }

        },
        getUserAndRole() {
            let user = this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },
        onUpdateSystem(item) {
            this.callback.view = 3;
            this.callback.update_system = item
            this.$emit("callback", this.callback);
        },
    },
    mounted() {
        this.getUserAndRole();
        this.getDuLieu();
    }
};
</script>
