<template>
    <div>
        <Breadcrumbs main="Quản lý hệ thống" title="Cập nhật hệ thống"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="updateForm"
                        :model="formUpdate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="name" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.name" placeholder="Nhập tên hệ thống"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ trụ sở <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="address" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.address" placeholder="Nhập địa chỉ trụ sở"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Số điện thoại <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="phone" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.phone" placeholder="Nhập số điện thoại hệ thống"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mô tả</label>
                        <el-form-item prop="des" class="col-md-9 col-10">
                            <el-input type="textarea" style="width: 100%; max-width:100%" v-model="formUpdate.des"
                                      placeholder="Nhập mô tả"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ website</label>
                        <el-form-item prop="web" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.web" placeholder="Nhập địa chỉ website"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ fanpage facebook</label>
                        <el-form-item prop="facebook" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.facebook" placeholder="Nhập tên hệ thống"/>
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Cập nhật
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import {put} from "@/m_utils/rest_api";

export default {
    props: ['update_system'],
    data() {
        return {
            callback: {
                view: 1
            },
            formUpdate: {
                name: '',
                avatar: '',
                address: '',
                phone: '',
                des: '',
                web: '',
                facebook: '',
            },
            fileList: [],
            fileAva: '',
            rules: {
                name: [
                    {required: true, message: 'Vui lòng nhập tên hệ thống', trigger: 'change'},
                ],
                address: [
                    {required: true, message: 'Vui lòng nhập địa chỉ trụ sở hệ thống', trigger: 'change'},
                ],
                phone: [
                    {required: true, message: 'Vui lòng nhập số điện thoại hệ thống', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const phoneRegex = /^0[0-9]{9,11}$/;
                            if (!phoneRegex.test(value)) {
                                return callback(new Error('Số điện thoại không hợp lệ'));
                            }
                            return callback();
                        },
                        trigger: 'change'
                    }
                ],
                web: [
                    {type: 'url', message: 'Vui lòng nhập định dạng website', trigger: 'change'},
                ],
                facebook: [
                    {type: 'url', message: 'Vui lòng nhập định dạng website', trigger: 'change'},
                ]
            }
        }
    },
    methods: {
        clickBack() {
            this.$emit("callback", this.callback);
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('updateForm')
        },
        onSubmit(updateForm) {
            this.$refs[updateForm].validate((valid) => {
                if (valid) {
                    this.onUpdate()
                } else {
                    return false;
                }
            });
        },
        async onUpdate() {
            let url = '/gym/admin/gym';
            let params = {
                id: this.update_system.id,
                name: this.formUpdate.name,
                head_quater_address: this.formUpdate.address,
                head_quater_phone: this.formUpdate.phone,
                description: this.formUpdate.des,
                website: this.formUpdate.web,
                facebook: this.formUpdate.facebook,
            };
            try {
                const res = await put(url, params)
                if (res.code === 200) {
                    this.$swal({
                        icon: 'success',
                        title: "Thông báo",
                        text: 'Cập nhật hệ thống thành công',
                    }).then((result) => {
                        if (result.value) {
                            this.$refs['updateForm'].resetFields();
                            this.clickBack()
                        } else {
                            this.$refs['updateForm'].resetFields();
                            this.clickBack()
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'danger',
                        title: "Thông báo",
                        text: res.message || 'Cập nhật hệ thống thất bại',
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('updateForm');
        },
        resetForm(updateForm) {
            this.$refs[updateForm].resetFields();
        },
        setValueForm(update_system) {
            this.formUpdate.name = update_system.name
            this.formUpdate.address = update_system.headQuaterAddress
            this.formUpdate.phone = update_system.headQuaterPhone
            this.formUpdate.des = update_system.description
            this.formUpdate.web = update_system.website
            this.formUpdate.facebook = update_system.facebook
        }
    },
    beforeMount() {
        this.setValueForm(this.update_system)
    },
}
</script>
<style scoped>

</style>