<template>
    <div>
        <Breadcrumbs main="Quản lý hệ thống" title="Thêm mới hệ thống"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="createForm"
                        :model="formCreate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="name" class="col-md-9 col-10">
                            <el-input v-model="formCreate.name" placeholder="Nhập tên hệ thống"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ trụ sở <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="address" class="col-md-9 col-10">
                            <el-input v-model="formCreate.address" placeholder="Nhập địa chỉ trụ sở"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Số điện thoại <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="phone" class="col-md-9 col-10">
                            <el-input v-model="formCreate.phone" placeholder="Nhập số điện thoại hệ thống"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mô tả</label>
                        <el-form-item prop="des" class="col-md-9 col-10">
                            <el-input type="textarea" style="width: 100%; max-width:100%" v-model="formCreate.des"
                                      placeholder="Nhập mô tả"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ website</label>
                        <el-form-item prop="web" class="col-md-9 col-10">
                            <el-input v-model="formCreate.web" placeholder="Nhập địa chỉ website"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ fanpage facebook</label>
                        <el-form-item prop="facebook" class="col-md-9 col-10">
                            <el-input v-model="formCreate.facebook" placeholder="Nhập tên hệ thống"/>
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Thêm mới
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import {post} from "@/m_utils/rest_api";

export default {
    data() {
        return {
            callback: {
                view: 1
            },
            formCreate: {
                name: '',
                avatar: '',
                address: '',
                phone: '',
                des: '',
                web: '',
                facebook: '',
            },
            fileList: [],
            fileAva: '',
            rules: {
                name: [
                    {required: true, message: 'Vui lòng nhập tên hệ thống', trigger: 'change'},
                ],
                address: [
                    {required: true, message: 'Vui lòng nhập địa chỉ trụ sở hệ thống', trigger: 'change'},
                ],
                phone: [
                    {required: true, message: 'Vui lòng nhập số điện thoại hệ thống', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const phoneRegex = /^0[0-9]{9,11}$/;
                            if (!phoneRegex.test(value)) {
                                return callback(new Error('Số điện thoại không hợp lệ'));
                            }
                            return callback();
                        },
                        trigger: 'change'
                    }
                ],
                web: [
                    {type: 'url', message: 'Vui lòng nhập định dạng website', trigger: 'change'},
                ],
                facebook: [
                    {type: 'url', message: 'Vui lòng nhập định dạng website', trigger: 'change'},
                ]
            }
        }
    },
    methods: {
        clickBack() {
            this.$emit("callback", this.callback);
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('createForm')
        },
        onSubmit(createForm) {
            this.$refs[createForm].validate((valid) => {
                if (valid) {
                    this.onCreate()
                } else {
                    return false;
                }
            });
        },
        async onCreate() {
            let url = '/gym/admin/gym';
            let params = {
                name: this.formCreate.name,
                head_quater_address: this.formCreate.address,
                head_quater_phone: this.formCreate.phone,
                description: this.formCreate.des,
                website: this.formCreate.web,
                facebook: this.formCreate.facebook,
                image_url: ''
            };
            try {
                const res = await post(url, params)
                if (res.code === 200) {
                    this.$swal({
                        icon: 'success',
                        title: "Thông báo",
                        text: 'Tạo hệ thống thành công',
                    }).then((result) => {
                        if (result.value) {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        } else {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'danger',
                        title: "Thông báo",
                        text: res.message || 'Tạo hệ thống thất bại',
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('createForm');
        },
        resetForm(createForm) {
            this.$refs[createForm].resetFields();
        },
    }
}
</script>
<style scoped>

</style>