<template>
    <div>
        <list-role v-if="view===1" @callback="callBack($event)" ></list-role>
        <create-role v-if="view===2" @callback="callBack($event)" ></create-role>
    </div>
</template>
<script>
import ListRole from "@/pages/role_management/list_role.vue";
import CreateRole from "@/pages/role_management/create_role.vue";

export default {
    components: {
        'listRole': ListRole,
        'createRole': CreateRole
    },
    data() {
        return {
            view: 1,
            role_update: {},
        }
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.role_update = step.role_update ? step.role_update : {}
        },

    },
}
</script>
<style scoped>

</style>