<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalDetailUser">Chi tiết tài khoản: {{ user.username }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body row">
                <div class="col-3 col-md-4">
                    <img v-if="user.avatar"
                         class="b-r-10"
                         :src="user.avatar"
                         alt=""
                         width="100%"/>
                </div>
                <div class="col-9 col-md-8">
                    <table class="table table-lg">
                        <tbody>
                        <tr>
                            <td>Loại tài khoản:</td>
                            <td>{{ getTypeAccount(user) }}</td>
                        </tr>
                        <tr>
                            <td>Tên đầy đủ:</td>
                            <td>{{ user.fullName || user.username }}</td>
                        </tr>
                        <tr>
                            <td>Địa chỉ</td>
                            <td>{{ user.addressOfEmp || 'Không có' }}</td>
                        </tr>
                        <tr>
                            <td>Hệ thống:</td>
                            <td>{{ user.gymName }}</td>
                        </tr>
                        <tr>
                            <td>Phòng tập:</td>
                            <td>{{ user.branch_name }}</td>
                        </tr>
                        <tr>
                            <td>Số điện thoại</td>
                            <td>{{ user.phoneOfEmp || 'Không có' }}</td>
                        </tr>
                        <tr>
                            <td>Địa chỉ email:</td>
                            <td>{{ user.email || 'Không có' }}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import constant from "@/constants/config";

export default {
    props: ["user"],
    methods: {
        getTypeAccount(account) {
            let roleId = account.roleId
            if (roleId === constant.SUPER_ROOT) {
                return 'Root'
            } else if (roleId === constant.GYM_SYSTEM) {
                return 'Quản lý hệ thống'
            } else if (roleId === constant.GYM_BRANCH) {
                let branchAccType = account.branchAccType
                if (branchAccType === 1) {
                    return 'Lễ tân'
                }
                if (branchAccType === constant.GYM_BRANCH_PT) {
                    return 'Huấn luyện viên'
                }
                if (branchAccType === constant.GYM_BRANCH_SALE) {
                    return 'Nhân viên sale'
                }
                return 'Quản lý phòng tập'
            } else if (roleId === constant.GYM_BRANCH_GROUP) {
                return ' Nhóm phòng tập'
            }
            return ''
        },
    },
}
</script>