<template>
    <div>
        <list-system v-if="view===1" @callback="callBack($event)"></list-system>
        <create-system v-if="view===2" @callback="callBack($event)"></create-system>
        <update-system v-if="view===3" @callback="callBack($event)" :update_system=update_system></update-system>
    </div>
</template>

<script>
import ListSystem from "@/pages/system/list_system.vue";
import CreateSystem from "@/pages/system/create_system.vue";
import UpdateSystem from "@/pages/system/update_system.vue";

export default {
    components: {
        'listSystem': ListSystem,
        'createSystem': CreateSystem,
        'updateSystem': UpdateSystem,
    },
    data() {
        return {
            view: 1,
            update_system: {},
        };
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.update_system = step.update_system ? step.update_system : {}
        },
    },
};
</script>

