<template>
    <div>
        <list-gym v-if="view===1" @callback="callBack($event)"></list-gym>
        <create-gym v-if="view===2" @callback="callBack($event)"></create-gym>
        <update-gym v-if="view===3" @callback="callBack($event)" :gym_update="gym_update"></update-gym>

    </div>
</template>

<script>
import ListGym from "@/pages/gym/list_gym.vue";
import CreateGym from "@/pages/gym/create_gym.vue";
import UpdateGym from "@/pages/gym/update_gym.vue";

export default {
    components: {
        'listGym': ListGym,
        'createGym': CreateGym,
        'UpdateGym': UpdateGym
    },
    data() {
        return {
            view: 1,
            gym_update: {},
        };
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.gym_update = step.gym_update ? step.gym_update : {}
        },
    },
};
</script>

