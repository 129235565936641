<template>
    <div>
        <Breadcrumbs title="Quản lý phân quyền tài khoản"/>
        <el-form
                ref="searchForm"
                style="width: 100%"
                :model="searchForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                label-position="left"
        >
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">Vai trò</label>
                    <el-form-item prop="role">
                        <el-select clearable
                                   collapse-tags
                                   filterable
                                   v-model="searchForm.role" style="width:100%"
                                   placeholder="--Chọn--">
                            <el-option
                                    v-for="item in list_source"
                                    :key="item.role"
                                    :label="item.name"
                                    :value="item.role"
                            />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-md-3" v-if="isRoot()">
                    <label class="form-label">Hệ thống</label>
                    <el-form-item prop="system_id">
                        <el-select clearable
                                   collapse-tags
                                   filterable
                                   @change="changeSystemID"
                                   v-model="searchForm.system_id" style="width:100%"
                                   placeholder="--Chọn--">
                            <el-option
                                    v-for="item in list_system"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-md-3" v-if="isGreaterThanBranch()">
                    <label class="form-label">Phòng tập</label>
                    <el-form-item prop="gym_id">
                        <el-select clearable
                                   collapse-tags
                                   filterable
                                   :disabled="list_gym.length<=0"
                                   v-model="searchForm.gym_id" style="width:100%"
                                   placeholder="--Chọn--">
                            <el-option
                                    v-for="item in list_gym"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <el-form-item>
                        <el-button @click.prevent="onClickSearch" type="primary">
                            Tìm kiếm
                        </el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>

        <div class="container-fluid">
            <div class="row justify-content-end mb-3">
                <el-button type="success" style="width:150px" @click="changeViewCreate">Thêm mới</el-button>
            </div>
        </div>
        <div class="row widget-grid">
            <div class="table-responsive">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="tableData.length==0 && show">
                    <p>Không tìm thấy kết quả phù hợp</p>
                    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <table class="table table-bordered table-hover" v-if="tableData.length>0">
                    <thead class="bg-primary">
                    <tr>
                        <th scope="col" width="50px">STT</th>
                        <th scope="col">Loại tài khoản</th>
                        <th scope="col">Tên hệ thống</th>
                        <th scope="col">Tên phòng tập</th>
                        <th scope="col">Thao tác</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>{{ getIndex(index) }}</td>
                        <td>{{ getRole(item.role_id) }}</td>
                        <td>{{ getSystem(item.gym_id) }}</td>
                        <td class="text-break" v-html="getGym(item.ds_gym_branch_id)"></td>
                        <td>
                            <button title="Xóa phân quyền này" @click="deletePermission(item)"
                                    class="btn btn-secondary mt-sm-2" type="button">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>

                </table>

            </div>
        </div>
        <div v-if="pagging.totalPage>0" class="mt-3">
            <el-pagination
                    v-model:current-page="pagging.current_page"
                    v-model:page-size="pagging.size"
                    :page-sizes="[20, 50, 100]"
                    layout="total, sizes, prev, pager, next"
                    :total="pagging.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>
<script>
import constant, {Secret_Key} from "@/constants/config";
import CryptoJS from "crypto-js";
import {get, post, remove} from "@/m_utils/rest_api";

export default {
    props: ['role', 'user'],
    data() {
        return {
            callback: {
                view: '',
                role_update: {},
            },
            searchForm: {
                role: '',
                system_id: '',
                gym_id: '',
            },
            rules: {
                system_id: [
                    {required: true, message: 'Vui lòng chọn hệ thống', trigger: 'change'},
                ]
            },
            user: {},
            role: '',
            list_source: [],
            list_gym: [],
            list_system: [],
            start: 0,
            limit: 20,
            tableData: [],
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
        }
    },
    methods: {
        changeViewCreate() {
            this.callback.view = 2
            this.$emit("callback", this.callback);
        },
        getIndex(index) {
            return (this.pagging.current_page - 1) * this.limit + index + 1
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },
        getUserAndRole() {
            let user = this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },
        async getListSource() {
            this.list_source = []
            let role = parseInt(this.role)
            if (this.user.username === 'root') {
                this.list_source.push({
                    role: constant.SUPER_ROOT,
                    name: 'Supper Admin'
                })
            }
            if (role <= constant.SUPER_ROOT) {
                this.list_source.push({
                    role: constant.GYM_SYSTEM,
                    name: 'Admin Hệ thống'
                })
            }
            if (role <= constant.GYM_SYSTEM) {
                this.list_source.push({
                    role: constant.GYM_BRANCH_GROUP,
                    name: 'Nhóm phòng tập'
                })
            }
            if (role <= constant.GYM_BRANCH_GROUP) {
                this.list_source.push({
                    role: constant.GYM_BRANCH,
                    name: 'Phòng tập'
                })
            }
            if (role === constant.GYM_BRANCH) {
                this.list_source.push({
                    role: constant.GYM_BRANCH_LT,
                    name: 'Lễ tân'
                })
                this.list_source.push({
                    role: constant.GYM_BRANCH_PT,
                    name: 'PT'
                })
                this.list_source.push({
                    role: constant.GYM_BRANCH_SALE,
                    name: 'Sale'
                })
                this.list_source.push({
                    role: constant.GYM_BRANCH_KS,
                    name: 'Kiểm soát'
                })
            }

            if (this.isRoot()) {
                this.getListSystem()
            } else {
                this.list_gym = await this.getListBranch(this.user.gym_id)
                this.searchForm.system_id = this.user.gym_id
                this.getDuLieu()
            }
            if (role === constant.GYM_BRANCH) {
                this.searchForm.system_id = this.user.gym_id
                this.searchForm.gym_id = this.user.branch_id
                this.getDuLieu()
            }
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_system = res.rows;
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system = res.rows
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        async changeSystemID() {
            this.searchForm.gym_id = '';
            if (this.searchForm.system_id === -1) {
                return
            }
            this.list_gym = await this.getListBranch(this.searchForm.system_id)
        },
        async getListBranch(system_id) {
            try {
                this.$store.dispatch('setLoading', true);
                let request_params = {
                    gymId: system_id,
                }
                let url = 'gym/gymbranch/getByGymId'
                const res = await get(url, request_params)
                if (res.code === 200) {
                    this.$store.dispatch('setLoading', false);
                    return res.rows
                } else {
                    this.$store.dispatch('setLoading', false);
                    return []
                }
            } catch (e) {
                this.$store.dispatch('setLoading', false);
                return []
            }
        },
        onClickSearch() {
            this.$refs['searchForm'].validate((valid) => {
                if (valid) {
                    this.start = 0;
                    this.limit = 20;
                    this.pagging.current_page = 1
                    this.getDuLieu()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async getDuLieu() {
            let url = '/admin/menu/menu-role';
            let params = {
                role_id: this.role !== constant.GYM_BRANCH ? this.searchForm.role : this.role,
                gym_id: this.searchForm.system_id,
                gym_branch_id: this.searchForm.gym_id,
                start: this.start,
                limit: this.limit,
                branch_acc_type: this.role === constant.GYM_BRANCH ? this.searchForm.role : ''
            }
            try {
                const res = await get(url, params)
                if (res.code === 200) {
                    if (res.list_data.length > 0) {
                        this.tableData = res.list_data
                        this.pagging.total = res.total
                        this.pagging.totalPage = res.total / this.pagging.size
                    } else {
                        this.tableData = [];
                        this.pagging.total = 0;
                        this.pagging.totalPage = 0;
                        this.show = true;
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.pagging.total = 0;
                    this.pagging.totalPage = 0;
                    this.show = true;
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.tableData = [];
                this.pagging.total = 0;
                this.pagging.totalPage = 0;
                this.show = true;
                this.$store.dispatch('setLoading', false);
            }
        },
        getSystem(system_id) {
            let system = this.list_system.find(item => item.id === system_id)
            if (system) {
                return system.name
            }
            return ''
        },
        getGym(ds_gym_id) {
            let gym_name = ''
            if (ds_gym_id) {
                ds_gym_id.forEach(gym_id => {
                    let gym = this.list_gym.find(item => item.id === gym_id)
                    if (gym) {
                        gym_name = gym_name === '' ? '- ' + gym.name : gym_name + '<br>' + '- ' + gym.name
                    }
                })
            }
            return gym_name
        },
        getRole(role_id) {
            let role = this.list_source.find(item => item.role === role_id)
            if (role) {
                return role.name
            }
            return ''
        },
        isGreaterThanBranch() {
            return parseInt(this.role) < constant.GYM_BRANCH
        },
        deletePermission(item) {
            this.$swal({
                icon: 'question',
                text: 'Bạn có muốn xóa phân quyền này?',
                showCancelButton: true,
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#e64942',
                cancelButtonText: 'Đóng',
                cancelButtonColor: '#efefef',
                reverseButtons: true,
                focusCancel: true,
            }).then(async (result) => {
                if (result.value) {
                    let url = '/admin/menu/menu-permission/' + item.id;
                    const res = await remove(url)
                    if (res.code === 200) {
                        this.$swal({
                            icon: 'success',
                            title: "Thông báo",
                            text: 'Xóa thành công',
                        });
                        this.onClickSearch()
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: "Thông báo",
                            text: 'Xóa thất bại',
                        });
                        this.onClickSearch()
                    }
                } else {
                    this.$swal.close()
                }
            });
        }

    },
    beforeMount() {
        this.getUserAndRole()
        this.getListSource()
    }
}
</script>
<style scoped>

</style>