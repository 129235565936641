export var menuItems = {
    "data": [
        {
            "title": "Dashboard",
            "icon": "stroke-home",
            "path": "/",
            "id": "DASH_BOARD",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false
        },
        {
            "title": "Cấu hình menu",
            "icon": "stroke-project",
            "path": "/menu",
            "id": "QL_MENU",
            "iconf": "stroke-project",
            "type": "link",
            "active": true,
            "show": false
        },
        {
            "title": "Quản lý phân quyền",
            "icon": "stroke-learning",
            "path": "/role-management",
            "id": "QL_RESOURCE",
            "iconf": "fill-learning",
            "type": "link",
            "active": true,
            "show": false
        },
        {
            "title": "Quản lý tài khoản hệ thống phòng tập",
            "icon": "user-visitor",
            "path": "/account",
            "id": "QL_TAI_KHOAN",
            "iconf": "user-visitor",
            "type": "link",
            "active": true,
            "show": false
        },
        {
            "title": "Quản lý khách hàng",
            "icon": "stroke-user",
            "iconf": "fill-user",
            "id": "QL_KHACH_HANG",
            "type": "sub",
            "active": false,
            "show": false,
            "children": [
                {
                    "path": "/customer/account",
                    "id": "TAI_KHOAN_KHACH_HANG",
                    "title": "Tài khoản khách hàng",
                    "type": "link",
                    "active": false,
                    "show": false

                },
                {
                    "path": "",
                    "id": "KHAO_SAT_KHACH_HANG",
                    "title": "Khảo sát khách hàng",
                    "type": "link",
                    "active": false,
                    "show": false

                },
                {
                    "path": "",
                    "id": "KE_HOACH_KHACH_HANG",
                    "title": "Kế hoạch khách hàng",
                    "type": "link",
                    "active": false,
                    "show": false

                },
            ]
        },
        {
            "title": "Quản lý hệ thống",
            "icon": "customers",
            "path": "/system",
            "id": "QL_HE_THONG",
            "iconf": "customers",
            "type": "link",
            "active": true,
            "show": false
        },
        {
            "title": "Quản lý phòng tập",
            "icon": "stroke-social",
            "path": "/gym",
            "id": "QL_PHONG_TAP",
            "iconf": "stroke-social",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý dịch vụ",
            "icon": "stroke-widget",
            "path": "/service",
            "id": "QL_DICH_VU",
            "iconf": "fill-widget",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý sản phẩm phòng tập",
            "icon": "product-management",
            "path": "/product-gym",
            "id": "QL_SAN_PHAM_PHONG_TAP",
            "iconf": "product-management",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý thiết bị",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_THIET_BI",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý thẻ từ - vân tay",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_THE_TU_VAN_TAY",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý gói tập",
            "icon": "stroke-job-search",
            "path": "/package",
            "id": "QL_GOI_TAP",
            "iconf": "fill-job-search",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý khuyến mãi",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_KHUYEN_MAI",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý gói tập combo",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_GOI_TAP_COMBO",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý gifcode",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_GIFCODE",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý yêu cầu mua gói",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_YEU_CAU_MUA_GOI",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý hội viên",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_HOI_VIEN",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý hợp đồng",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_HOP_DONG",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý check in - check out",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_CHECKIN_CHECKOUT",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý nhân viên",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_NHAN_VIEN",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Quản lý nhân viên check in",
            "icon": "stroke-home",
            "path": "",
            "id": "QL_NHAN_VIEN_CHECK_IN",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Giám sát phòng tập",
            "icon": "stroke-home",
            "path": "",
            "id": "GIAM_SAT_PHONG_TAP",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false

        },
        {
            "title": "Trực phòng tập",
            "icon": "stroke-home",
            "path": "",
            "id": "TRUC_PHONG_TAP",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": false
        },
        {
            "title": "Báo cáo",
            "icon": "stroke-home",
            "path": "",
            "id": "BAO_CAO",
            "iconf": "fill-home",
            "type": "link",
            "active": true,
            "show": true
        },
        // {
        //     "title": "Error Page",
        //     "icon": "stroke-internationalization",
        //     "iconf": "fill-internationalization",
        //     "type": "sub",
        //     "active": true,
        //     "children": [
        //         {
        //             "path": "/error-400",
        //             "title": "Error 400",
        //             "type": "link",
        //             "active": true
        //         },
        //         {
        //             "path": "/error-401",
        //             "title": "Error 401",
        //             "type": "link",
        //             "active": true
        //         },
        //         {
        //             "path": "/error-403",
        //             "title": "Error 403",
        //             "type": "link",
        //             "active": true
        //         },
        //         {
        //             "path": "/error-404",
        //             "title": "Error 404",
        //             "type": "link",
        //             "active": true
        //         },
        //         {
        //             "path": "/error-500",
        //             "title": "Error 500",
        //             "type": "link",
        //             "active": true
        //         },
        //         {
        //             "path": "/error-503",
        //             "title": "Error 503",
        //             "type": "link",
        //             "active": true
        //         }
        //     ]
        // },
    ]
}