<template>
    <div>
        <Breadcrumbs main="Quản lý khách hàng" title="Quản lý tài khoản khách hàng"/>
        <el-form
                ref="searchForm"
                style="width: 100%"
                :model="searchForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                label-position="left"
        >
            <div class="row">
                <div class="col-md-3" v-if="isRoot()">
                    <label class="form-label">Hệ thống</label>
                    <el-form-item prop="system_id">
                        <el-select clearable
                                   collapse-tags
                                   filterable
                                   v-model="searchForm.system_id" style="width:100%" placeholder="--Chọn--">
                            <el-option
                                    v-for="item in list_system"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Họ tên</label>
                    <el-form-item prop="name">
                        <el-input v-model="searchForm.name" placeholder="Nhập họ tên"/>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Tên hiển thị</label>
                    <el-form-item prop="display_name">
                        <el-input v-model="searchForm.display_name" placeholder="Nhập tên hiển thị"/>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Số điện thoại</label>
                    <el-form-item prop="phone">
                        <el-input v-model="searchForm.phone" placeholder="Nhập số điện thoại"/>
                    </el-form-item>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <el-form-item>
                        <el-button @click="onClickSearch" type="primary">
                            Tìm kiếm
                        </el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <div class="container-fluid">
            <div class="row justify-content-end mb-3">
                <el-button type="success" style="width:150px" @click="changeViewCreate">Thêm mới</el-button>
            </div>
        </div>
        <div class="row widget-grid">
            <div class="table-responsive">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="tableData.length==0 && show">
                    <p>Không tìm thấy kết quả phù hợp</p>
                    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <table class="table table-bordered table-hover" v-if="tableData.length>0">
                    <thead class="bg-primary">
                    <tr>
                        <th scope="col" style="width:3rem">STT</th>
                        <th style="width:10rem" scope="col">Hệ thống</th>
                        <th style="width:12rem" scope="col">Ảnh đại diện</th>
                        <th scope="col">Họ tên</th>
                        <th scope="col">Ngày sinh</th>
                        <th scope="col">Giới tính</th>
                        <th scope="col">Số điện thoại</th>
                        <th style="width:14rem" scope="col">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td>{{ getIndex(index) }}</td>
                        <td>{{ item.gym[0]?.description }}</td>
                        <td>
                            <img v-if="item.avatar"
                                 class="b-r-10"
                                 :src="item.avatar"
                                 alt=""
                                 width="100%"/>
                        </td>
                        <td class="text-break">{{ item.displayName }}</td>
                        <td>{{ item.birthDay }}</td>
                        <td>{{ item.gender == 0 ? 'Nam' : 'Nữ' }}</td>
                        <td>{{ item.phoneNumber }}</td>
                        <td>
                            <button @click="showDetail(item)" data-bs-toggle="modal"
                                    data-bs-target="#modalDetailCustomerAccount"
                                    title="Xem thông tin tài khoản khách hàng"
                                    class="btn btn-primary mt-sm-2 me-2">
                                <i class="fa fa-eye"></i>
                            </button>

                            <button title="Sửa thông tin tài khoản khách hàng" @click.prevent="onUpdateCustomerAccount(item)"
                                    class="btn btn-warning mt-sm-2 me-2" type="button">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button title="Xóa tài khoản khách hàng" @click="deleteCustomerAccount(item)"
                                    class="btn btn-secondary mt-sm-2 me-2" type="button">
                                <i class="fa fa-trash-o"></i>
                            </button>
                            <button title="Mở khóa tài khoản"
                                    class="btn btn-success mr-2 mt-2" type="button">
                                <i class="fa fa-unlock-alt"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>

                </table>

            </div>
        </div>
        <div v-if="pagging.totalPage>0" class="mt-3">
            <el-pagination
                    v-model:current-page="pagging.current_page"
                    v-model:page-size="pagging.size"
                    :page-sizes="[20, 50, 100]"
                    layout="total, sizes, prev, pager, next"
                    :total="pagging.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
        <div class="modal fade" id="modalDetailCustomerAccount" tabindex="-1" role="dialog"
             aria-labelledby="modalDetailCustomerAccount" aria-hidden="true">
            <ModalDetail :customer_account="customer_account_detail"/>
        </div>
    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';
import constant, {Secret_Key} from '/src/constants/config'
import ModalDetail from "@/pages/customer/account/modal/modal_detail.vue";
import CryptoJS from "crypto-js";

export default {
    components: {
        'ModalDetail': ModalDetail,
    },
    data() {
        return {
            searchForm: {
                system_id: [],
                name: '',
                display_name: '',
                phone: '',
            },
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            callback: {
                view: '',
                user_update: {},
            },
            show: false,
            start: 0,
            limit: 20,
            rules: {},
            tableData: [],
            list_system: [],
            customer_account_detail: {},
            customer_account_update_gym: {},
            role: '',
            user: {},
        };
    },
    methods: {
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        showDetail(customer_account) {
            this.customer_account_detail = customer_account
        },
        deleteCustomerAccount(item) {
            this.$swal({
                icon: 'question',
                text: 'Bạn có muốn xóa tài khoản khách hàng này?',
                showCancelButton: true,
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#e64942',
                cancelButtonText: 'Đóng',
                cancelButtonColor: '#efefef',
                reverseButtons: true,
                focusCancel: true,
            }).then(async (result) => {
                this.$store.dispatch('setLoading', true);
                if (result.value) {
                    let url = 'admin/end-user/' + item.userId;
                    const res = await remove(url)
                    if (res.code === 200) {
                        this.$store.dispatch('setLoading', false);
                        this.$swal({
                            icon: 'success',
                            title: "Thông báo",
                            text: 'Xóa tài khoản khách hàng thành công',
                        });
                        this.onClickSearch()
                    } else {
                        this.$store.dispatch('setLoading', false);

                        this.$swal({
                            icon: 'error',
                            title: "Thông báo",
                            text: 'Xóa tài khoản khách hàng thất bại',
                        });
                        this.onClickSearch()
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.$swal.close()
                }
            });
        },
        onClickSearch() {
            this.start = 0;
            this.limit = 20;
            this.pagging.current_page = 1
            this.getDuLieu()
        },
        changeViewCreate() {
            this.callback.view = 2
            this.$emit("callback", this.callback);
        },
        getIndex(index) {
            return (this.pagging.current_page - 1) * this.limit + index + 1
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },

        async getDuLieu() {
            this.$store.dispatch('setLoading', true);
            if (!this.isRoot()) {
                this.searchForm.system_id = this.user.gym_id
            }
            let params = {
                'start': this.start,
                'limit': this.limit,
            }
            if (this.searchForm.system_id) {
                params.gym_id = this.searchForm.system_id
            }
            if (this.searchForm.name) {
                params.real_name = this.searchForm.name
            }
            if (this.searchForm.display_name) {
                params.display_name = this.searchForm.display_name
            }
            if (this.searchForm.phone) {
                params.phone_number = this.searchForm.phone
            }
            let url = 'admin/end-user/all'
            try {
                const res = await get(url, params)
                if (res.code === 200) {
                    if (res.userInfoIdentifings.length > 0) {
                        this.tableData = res.userInfoIdentifings
                        this.pagging.total = res.total
                        this.pagging.totalPage = res.total / this.pagging.size
                    } else {
                        this.tableData = [];
                        this.pagging.total = 0;
                        this.pagging.totalPage = 0;
                        this.show = true;
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.pagging.total = 0;
                    this.pagging.totalPage = 0;
                    this.show = true;
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.tableData = [];
                this.pagging.total = 0;
                this.pagging.totalPage = 0;
                this.show = true;
                this.$store.dispatch('setLoading', false);
            }

        },
        getUserAndRole() {
            let user =  this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_system = res.rows;
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system.push = res.rows;
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        onUpdateCustomerAccount(user){
            this.callback.view = 3;
            this.callback.user_update = user
            this.$emit("callback", this.callback);
        },
    },
    mounted() {
        this.getUserAndRole();
        this.getDuLieu();
        this.getListSystem();
    }
};
</script>
