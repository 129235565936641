export default {
    convertDate(time) {
        if (isNaN(time)) {
            return "";
        }
        let a = new Date(time).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"});
        let d = new Date(a);
        return this.dateToString(d);
    },
    dateToString(date) {
        if (!this.isDate(date)) {
            return '';
        }
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        return ((day >= 10 ? day : "0" + day) + '/' + (month >= 10 ? month : "0" + month) + '/' + year);
    },
    isDate(date) {
        return Object.prototype.toString.call(date) === '[object Date]';
    },

}