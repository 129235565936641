<template>
    <div>
        <Breadcrumbs main="Quản lý khách hàng" title="Tài khoản khách hàng" sub="Cập nhật tài khoản khách hàng"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="updateForm"
                        :model="formUpdate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100" v-if="isRoot()">
                        <label class="col-md-3 col-2 align-content-center f-16">Hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="system_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       v-model="formUpdate.system_id" style="width:100%" placeholder="--Chọn--">
                                <el-option
                                        v-for="item in list_system"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Họ và tên <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="fullname" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.fullname"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên hiển thị <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="display_name" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.display_name"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Giới tính <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="gender" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       v-model="formUpdate.gender" style="width:100%" placeholder="--Chọn--">
                                <el-option
                                        v-for="item in list_gender"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Ngày sinh <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="birthday" class="col-md-9 col-10">
                            <el-date-picker
                                    style="width:100%"
                                    v-model="formUpdate.birthday"
                                    type="date"
                                    placeholder="Ngày/tháng/năm"
                                    :disabled-date="disabledDate"
                                    format="DD/MM/YYYY"
                                    value-format="DD/MM/YYYY"
                            />
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Email <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="email" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.email"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Số điện thoại <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="phone" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.phone"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mật khẩu <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="password" class="col-md-9 col-10">
                            <el-input type="password"
                                      v-model="formUpdate.password"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Nhập lại mật khẩu <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="re_password" class="col-md-9 col-10">
                            <el-input type="password" v-model="formUpdate.re_password"
                                     />
                        </el-form-item>
                    </div>
<!--                    <div class="row">-->
<!--                        <p class="fw-bold f-20 col-2">Thông tin thêm</p>-->
<!--                        <diV class="col-10">-->
<!--                            <hr class="border-3">-->
<!--                        </diV>-->
<!--                    </div>-->
<!--                    <div class="row w-100">-->
<!--                        <label class="col-md-3 col-2 align-content-center f-16">Chiều cao</label>-->
<!--                        <el-form-item prop="height" class="col-md-9 col-10">-->
<!--                            <el-input-number-->
<!--                                    style="width: 100%;"-->
<!--                                    v-model="formUpdate.height"-->
<!--                                    controls-position="right"-->
<!--                                    :min="1"-->
<!--                                    :max="5"-->
<!--                                    :precision="2"-->
<!--                                    :step="0.01"-->
<!--                            ></el-input-number>-->
<!--                        </el-form-item>-->
<!--                    </div>-->
<!--                    <div class="row w-100">-->
<!--                        <label class="col-md-3 col-2 align-content-center f-16">Cân nặng </label>-->
<!--                        <el-form-item prop="weight" class="col-md-9 col-10">-->
<!--                            <el-input-number-->
<!--                                    style="width: 100%;"-->
<!--                                    v-model="formUpdate.weight"-->
<!--                                    controls-position="right"-->
<!--                                    :min="1"-->
<!--                                    :max="200"-->
<!--                                    :precision="2"-->
<!--                                    :step="0.5"-->
<!--                            ></el-input-number>-->
<!--                        </el-form-item>-->
<!--                    </div>-->
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Cập nhật
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>

</template>
<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';
import constant, {Secret_Key} from '/src/constants/config'
import CryptoJS from "crypto-js";
import md5 from "crypto-js/md5";

export default {
    props:['user_update'],
    data() {
        return {
            user: {},
            role: '',
            list_system: [],
            list_gender: [
                {
                    name: 'Nam',
                    value: 0
                },
                {
                    name: 'Nữ',
                    value: 1
                }
            ],
            formUpdate: {
                system_id: '',
                fullname: '',
                display_name: '',
                gender: '',
                birthday: '',
                email: '',
                phone: '',
                password: '',
                re_password: '',
                height: 1,
                weight: 1,
            },
            rules: {
                system_id: [
                    {required: true, message: 'Vui lòng chọn hệ thống', trigger: 'change'},
                ],
                fullname: [
                    {required: true, message: 'Vui lòng nhập họ và tên', trigger: 'change'},
                ],
                display_name: [
                    {required: true, message: 'Vui lòng nhập tên hiển thị', trigger: 'change'},
                ],
                gender: [
                    {required: true, message: 'Vui lòng chọn giới tính', trigger: 'change'},
                ],
                birthday: [
                    {required: true, message: 'Vui lòng nhập ngày sinh', trigger: 'change'},
                ],
                email: [
                    {required: true, message: 'Vui lòng nhập email', trigger: 'change'},
                    {type: 'email', message: 'Vui lòng nhập định dạng email', trigger: 'change'},
                ],
                phone: [
                    {required: true, message: 'Vui lòng nhập số điện thoại', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const phoneRegex = /^0[0-9]{9,11}$/;
                            if (!phoneRegex.test(value)) {
                                return callback(new Error('Số điện thoại không hợp lệ'));
                            }
                            return callback();
                        },
                        trigger: 'change'
                    }
                ],
                password: [
                    {min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const hasLowerCase = /[a-z]/.test(value);
                            const hasUpperCase = /[A-Z]/.test(value);
                            const hasNumber = /\d/.test(value);
                            if ((!hasLowerCase || !hasUpperCase || !hasNumber) && value !== '') {
                                callback(new Error('Mật khẩu phải chứa ít nhất một chữ thường, một chữ hoa và một chữ số'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                re_password: [
                    {min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            if (value === '' && this.formUpdate.password != '') {
                                callback(new Error('Vui lòng nhập nhập lại mật khẩu'));
                            } else {
                                callback();
                            }
                            if (value !== this.formUpdate.password) {
                                callback(new Error('Mật khẩu nhập lại không khớp'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
            },
            callback: {
                view: 1
            },
        }
    },
    methods: {

        clickBack() {
            this.$emit("callback", this.callback);
        },
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        getUserAndRole() {
            let user =  this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            if (this.role === constant.SUPER_ROOT) {
                this.getListSystem()
            }
            else {
                this.formUpdate.system_id = this.user.gym_id
            }
            this.user = decryptedUser
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_system.push(...res.rows);
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system.push(...res.rows);
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        disabledDate(time) {
            return time.getTime() > Date.now()
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('updateForm')
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('updateForm');
        },
        resetForm(updateForm) {
            this.$refs[updateForm].resetFields();
        },
        onSubmit(updateForm) {
            if (!this.isRoot()) {
                this.formUpdate.system_id = this.user.gym_id
            }
            this.$refs[updateForm].validate((valid) => {
                if (valid) {
                    this.onUpdate()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async onUpdate() {
            let params = {
                gymId: this.formUpdate.system_id,
                realName: this.formUpdate.fullname,
                displayName: this.formUpdate.display_name,
                gender: this.formUpdate.gender,
                birthDay: this.formUpdate.birthday,
                email: this.formUpdate.email,
                phoneNumber: this.formUpdate.phone,
                height: this.formUpdate.height,
                weight: this.formUpdate.weight,
                userId: this.user_update.userId,
            }
            if(this.formUpdate.password){
                params.password= this.formUpdate.password
            }
            let url = 'admin/end-user'
            const res = await put(url, params)
            if (res.code === 200) {
                this.$swal({
                    icon: 'success',
                    title: "Thông báo",
                    text: 'Cập nhật tài khoản thành công',
                }).then((result) => {
                    if (result.value) {
                        this.$refs['updateForm'].resetFields();
                        this.clickBack()
                    } else {
                        this.$refs['updateForm'].resetFields();
                        this.clickBack()
                    }
                });
            } else {
                this.$swal({
                    icon: 'error',
                    title: "Thông báo",
                    text: res.message || 'Cập nhật tài khoản thất bại',
                })
            }
        },
        setValueForm(user_update){
            this.formUpdate.fullname= user_update.realName
            this.formUpdate.display_name=user_update.displayName
            if(this.isRoot())
            {
                this.formUpdate.system_id=user_update.gym[0]?.id||''
            }
            this.formUpdate.gender=user_update.gender
            this.formUpdate.email = user_update.email
            this.formUpdate.birthday = user_update.birthDay
            this.formUpdate.phone = user_update.phoneNumber
        }
    },
    beforeMount() {
        this.getUserAndRole()
        this.setValueForm(this.user_update)

    },
}
</script>
<style scoped>

</style>