<template>
    <div class="modal-dialog modal-dialog-centered" >
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalDetailGym">QR phòng tập: {{ gym.name }}</h4>
                <button class="btn-close" @click="clickClose()" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex justify-content-center">
                <img v-if="qr_code"
                     :src="qr_code"
                     alt=""
                     height="300px"/>
            </div>
            <div class="modal-footer">
                <button class="btn btn-light" @click="clickClose()" data-bs-dismiss="modal">Đóng</button>
            </div>
        </div>
    </div>
</template>
<script>
import {get, remove} from '/src/m_utils/rest_api'

export default {
    props: ["show","gym"],
    data() {
        return {
            qr_code:''
        }
    },
    methods: {
        async getQRCode(gym){
            let url = '/gym/admin/checkin/gen-qrcode'
            let params = {
                branchId : gym.id
            }
            try{
                const res= await get(url,params)
                this.qr_code = res.message
            }catch (e) {
                return ''
            }
            return ''
        },
        clickClose(){
            this.$emit("close");
        }

    },
    watch: {
        show: function (newVal, oldVal) {
            if(newVal){
                this.getQRCode(this.gym)
            }
        }
    }

}
</script>