<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalDetailService">Chi tiết gói tập: {{ package.packageName }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-responsive-md">
                    <tbody>
                    <tr>
                        <td>Tên gói tập:</td>
                        <td>{{ package.packageName }}</td>
                    </tr>
                    <tr>
                        <td>Mã gói tập:</td>
                        <td>{{ package.package_code }}</td>
                    </tr>
                    <tr>
                        <td>Hệ thống phòng tập:</td>
                        <td>{{ package.gymName }}</td>
                    </tr>
                    <tr>
                        <td>Giá gói tập:</td>
                        <td>{{ convertMoney(package.price) }}</td>
                    </tr>
                    <tr>
                        <td>Tổng thời gian tập:</td>
                        <td>{{convertTotalTime(package.durationType,package.totalDuration)}}</td>
                    </tr>
                    <tr>
                        <td>Ngày bắt đầu:</td>
                        <td>{{ convertDate(package.startTimeEffect) }}</td>
                    </tr>
                    <tr>
                        <td>Ngày kết thúc:</td>
                        <td>{{ convertDate(package.endTimeEffect) }}</td>
                    </tr>
                    <tr>
                        <td>Mô tả:</td>
                        <td v-html="package.longDescription"></td>
                    </tr>
                    <tr>
                        <td>Hệ thống phòng tập:</td>
                        <td>{{ package.gymName }}</td>
                    </tr>
                    </tbody>
                </table>
                <div class="row mt-4 mx-0">
                    <h5>Ràng buộc gói tập</h5>
                    <hr style="margin-left: -12px !important;">
                    <div class="col-6">
                        <p>Số lần bảo lưu: {{package.numReserve}}</p>
                        <p>Số ngày bảo lưu tối đa:{{package.maxNumReserve}}</p>
                        <p>Số ngày còn lại của gói bảo lưu:{{package.reserveDayValid}}</p>
                    </div>
                    <div class="col-6">
                        <p>Số lần chuyển nhượng:{{package.numTransfer}}</p>
                        <p>Số ngày còn lại của gói chuyển nhượng:{{package.transferDayValid}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import time_utils from "@/m_utils/time_utils";

export default {
    props: ["package"],
    data() {
        return {
            lstGym: [],
        }
    },
    methods: {
        convertDate(date) {
            return time_utils.convertDate(date);
        },
        convertMoney(price) {
            if(price)
            {
                return price.toLocaleString('vi-VN') + ' VNĐ';
            }
            return ''
        },
        convertTotalTime(type,time){
            if(type){
                return time +' tháng'
            }
            return time + ' ngày'
        }
    },
    watch: {}
}
</script>