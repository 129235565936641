<template>
    <div>
        <Breadcrumbs title="Quản lý tài khoản hệ thống phòng tập"/>
        <el-form
                ref="searchForm"
                style="width: 100%"
                :model="searchForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                label-position="left"
        >
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">Tên tài khoản</label>
                    <el-form-item prop="name">
                        <el-input v-model="searchForm.name"/>
                    </el-form-item>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                    <el-form-item>
                        <el-button @click="onClickSearch" type="primary">
                            Tìm kiếm
                        </el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <div class="container-fluid">
            <div class="row justify-content-end mb-3">
                <el-button type="success" style="width:150px" @click="changeViewCreate">Thêm mới</el-button>
            </div>
        </div>
        <div class="row widget-grid">
            <div class="table-responsive">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                     v-if="tableData.length==0 && show">
                    <p>Không tìm thấy kết quả phù hợp</p>
                    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <table class="table table-bordered table-hover" v-if="tableData.length>0">
                    <thead class="bg-primary">
                    <tr>
                        <th scope="col" width="50px">STT</th>
                        <th scope="col" v-if="isRoot()">Hệ thống</th>
                        <th scope="col">Phòng tập</th>
                        <th scope="col" style="width:10rem">Tên tài khoản</th>
                        <th scope="col" style="width:10rem">Tên người dùng</th>
                        <th scope="col" style="width:10rem">Email</th>
                        <th scope="col">SĐT</th>
                        <th scope="col">Vai trò</th>
                        <th scope="col">Trạng thái</th>
                        <th scope="col" style="width:10rem">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                        <td scope="row">{{ getIndex(index) }}</td>
                        <td scope="row" v-if="isRoot()">{{ item.gymName }}</td>
                        <td scope="row">{{ item.branch_name }}</td>
                        <td scope="row" class="text-break">{{ item.username }}</td>
                        <td scope="row" class="text-break">{{ item.fullName }}</td>
                        <td scope="row" class="text-break">{{ item.email }}</td>
                        <td scope="row">{{ item.phoneOfEmp || '' }}</td>
                        <td scope="row"> {{ getTypeAccount(item) }}</td>
                        <td scope="row">
                            {{ item.userStatus === 3 ? 'Đang hoạt động' : 'Tạm khóa' }}
                        </td>
                        <td v-if="item.gymId != 0">
                            <button @click="showDetail(item)" data-bs-toggle="modal"
                                    data-bs-target="#modalDetailUser" title="Xem thông tin tài khoản"
                                    class="btn btn-primary mt-sm-2 me-2">
                                <i class="fa fa-eye"></i>
                            </button>
                            <button title="Sửa thông tin tài khoản" @click="onUpdateAccount(item)"
                                    class="btn btn-warning mt-sm-2 me-2" type="button">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button v-if="item.userStatus===3" title="Khóa tài khoản" @click="deleteAccount(item)"
                                    class="btn btn-secondary mr-2 mt-2" type="button">
                                <i class="fa fa-lock"></i>
                            </button>
                            <button v-else title="Mở khóa tài khoản" @click="deleteAccount(item)"
                                    class="btn btn-success mr-2 mt-2" type="button">
                                <i class="fa fa-unlock-alt"></i>
                            </button>
                        </td>
                        <td v-else>
                            <button @click="showDetail(item)" data-bs-toggle="modal"
                                    data-bs-target="#modalDetailUser" title="Xem thông tin tài khoản"
                                    class="btn btn-primary mt-sm-2 me-2">
                                <i class="fa fa-eye"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>

                </table>

            </div>
        </div>
        <div v-if="pagging.totalPage>0" class="mt-3">
            <el-pagination
                    v-model:current-page="pagging.current_page"
                    v-model:page-size="pagging.size"
                    :page-sizes="[20, 50, 100]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagging.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
        <div class="modal fade" id="modalDetailUser" tabindex="-1" role="dialog"
             aria-labelledby="modalDetailUser" aria-hidden="true">
            <ModalDetail :user="user_detail"/>
        </div>
    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';
import constant, {Secret_Key} from '/src/constants/config'
import ModalDetail from "@/pages/account/modal/modal_detail";
import CryptoJS from "crypto-js";

export default {
    components: {
        'ModalDetail': ModalDetail,
    },
    data() {
        return {
            searchForm: {
                name: '',
            },
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            callback: {
                view: '',
                user_update: {},
            },
            show: false,
            start: 0,
            limit: 20,
            rules: {},
            tableData: [],
            user_detail: {},
            role: '',
            user: {},
        };
    },
    methods: {
        deleteAccount(item) {
            this.$swal({
                icon: 'question',
                text: 'Bạn có muốn chuyển đổi trạng thái tài khoản này?',
                showCancelButton: true,
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#e64942',
                cancelButtonText: 'Đóng',
                cancelButtonColor: '#efefef',
                reverseButtons: true,
                focusCancel: true,
            }).then(async (result) => {
                if (result.value) {
                    this.$store.dispatch('setLoading', true);
                    let url = '/admin/user-admin/' + item.userId;
                    const res = await remove(url)
                    if (res.code === 200) {
                        this.$store.dispatch('setLoading', false);
                        this.$swal({
                            icon: 'success',
                            title: "Thông báo",
                            text: 'Chuyển đổi trạng thái tài khoản của khách hàng thành công',
                        });
                        this.onClickSearch()
                    } else {
                        this.$store.dispatch('setLoading', false);
                        this.$swal({
                            icon: 'error',
                            title: "Thông báo",
                            text: res.message || 'Chuyển đổi trạng thái tài khoản của khách hàng thất bại',
                        });
                        this.onClickSearch()
                    }
                } else {
                    this.$swal.close()
                }
            });
        },
        onClickSearch() {
            this.start = 0;
            this.limit = 20;
            this.pagging.current_page = 1
            this.getDuLieu()
        },
        onUpdateAccount(item) {
            this.callback.view = 3;
            this.callback.user_update = item
            this.$emit("callback", this.callback);
        },
        changeViewCreate() {
            this.callback.view = 2
            this.$emit("callback", this.callback);
        },
        showDetail(user) {
            this.user_detail = user
        },
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        getIndex(index) {
            return (this.pagging.current_page - 1) * this.limit + index + 1
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },
        getTypeAccount(account) {
            let roleId = account.roleId
            if (roleId === constant.SUPER_ROOT) {
                return 'Root'
            } else if (roleId === constant.GYM_SYSTEM) {
                return 'Quản lý hệ thống'
            } else if (roleId === constant.GYM_BRANCH) {
                let branchAccType = account.branchAccType
                if (branchAccType === 1) {
                    return 'Lễ tân'
                }
                if (branchAccType === constant.GYM_BRANCH_PT) {
                    return 'Huấn luyện viên'
                }
                if (branchAccType === constant.GYM_BRANCH_SALE) {
                    return 'Nhân viên sale'
                }
                return 'Quản lý phòng tập'
            } else if (roleId === constant.GYM_BRANCH_GROUP) {
                return ' Nhóm phòng tập'
            }
            return ''
        },
        async getDuLieu() {
            this.$store.dispatch('setLoading', true);
            let params = {
                'start': this.start,
                'limit': this.limit,
                'key_search': this.searchForm.name ? this.searchForm.name.trim() : ''
            }
            if (this.role === constant.GYM_SYSTEM || this.role === constant.GYM_BRANCH_GROUP) {
                params.gymIdReq = this.user.gym_id
            }
            if (this.role === constant.GYM_BRANCH) {
                params.gymIdReq = this.user.gym_id
                params.branchIdReq = this.user.branch_id
            }
            let url = '/gym/admin/manage'
            try {
                const res = await get(url, params)
                if (res.code === 200) {
                    if (res.rows.length > 0) {
                        this.tableData = res.rows
                        this.pagging.total = res.total
                        this.pagging.totalPage = res.total / this.pagging.size
                    } else {
                        this.tableData = [];
                        this.pagging.total = 0;
                        this.pagging.totalPage = 0;
                        this.show = true;
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.pagging.total = 0;
                    this.pagging.totalPage = 0;
                    this.show = true;
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.tableData = [];
                this.pagging.total = 0;
                this.pagging.totalPage = 0;
                this.show = true;
                this.$store.dispatch('setLoading', false);
            }

        },
        getUserAndRole() {
            let user = this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },

    },
    mounted() {
        this.getUserAndRole();
        this.getDuLieu();
    }
};
</script>

