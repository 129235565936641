<template>
    <div>
        <Breadcrumbs main="Quản lý tài khoản" title="Thêm mới tài khoản hệ thống phòng tập"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="createForm"
                        :model="formCreate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100" v-if="list_source.length>0">
                        <label class="col-md-3 col-2 align-content-center f-16">Vai trò <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="role" class="col-md-9 col-10">
                            <el-select v-model="formCreate.role" style="width:100%" @change="changeRole"
                                       placeholder="Chọn vai trò">
                                <el-option
                                        v-for="item in list_source"
                                        :key="item.role"
                                        :label="item.name"
                                        :value="item.role"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên đăng nhập <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="username" class="col-md-9 col-10">
                            <el-input v-model="formCreate.username" placeholder="Nhập tên đăng nhập"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mật khẩu <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="password" class="col-md-9 col-10">
                            <el-input type="password"
                                      v-model="formCreate.password" placeholder="Nhập mật khẩu"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Nhập lại mật khẩu <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="re_password" class="col-md-9 col-10">
                            <el-input type="password" v-model="formCreate.re_password"
                                      placeholder="Nhập nhập lại mật khẩu"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Họ và tên <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="fullname" class="col-md-9 col-10">
                            <el-input v-model="formCreate.fullname" placeholder="Nhập họ và tên"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ </label>
                        <el-form-item prop="address" class="col-md-9 col-10">
                            <el-input v-model="formCreate.address" placeholder="Nhập địa chỉ"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ email </label>
                        <el-form-item prop="email" class="col-md-9 col-10">
                            <el-input v-model="formCreate.email" placeholder="Nhập địa chỉ email"/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Số điện thoại</label>
                        <el-form-item prop="phone" class="col-md-9 col-10">
                            <el-input v-model="formCreate.phone" placeholder="Nhập số điện thoại"/>
                        </el-form-item>
                    </div>
                    <!--                    <div class="row w-100">-->
                    <!--                        <label class="col-md-3 col-2 align-content-center f-16">Ảnh đại diện </label>-->
                    <!--                        <el-form-item prop="avatar" class="col-md-9 col-10">-->
                    <!--                            <el-upload-->
                    <!--                                    :on-remove="onRemoveAva"-->
                    <!--                                    :on-change="onChangeAva"-->
                    <!--                                    :on-preview="onPreviewAva"-->
                    <!--                                    action="/"-->
                    <!--                                    name="files"-->
                    <!--                                    class="upload-demo"-->
                    <!--                                    ref="upload"-->
                    <!--                                    :accept="'.jpg,.png,.jpeg'"-->
                    <!--                                    :file-list="fileList"-->
                    <!--                                    :auto-upload="false"-->
                    <!--                                    list-type="picture-card"-->
                    <!--                                    :limit="1"-->
                    <!--                                    :multiple="false"-->
                    <!--                            >-->
                    <!--                                <i class="fa fa-plus"></i>-->
                    <!--                            </el-upload>-->
                    <!--                        </el-form-item>-->
                    <!--                    </div>-->
                    <div class="row w-100" v-if="isRoot()">
                        <label class="col-md-3 col-2 align-content-center f-16">Hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="gym_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       @change="changeGymID"
                                       v-model="formCreate.gym_id" style="width:100%" placeholder="Chọn hệ thống">
                                <el-option
                                        v-for="item in list_gym"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-if="isGreaterThanBranch() && showChooseBranch()" class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Phòng tập <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="branch_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       no-data-text="Không tìm thấy phòng tập nào"
                                       :multiple="formCreate.role == 3? true: false"
                                       v-model="formCreate.branch_id" style="width:100%" placeholder="Chọn phòng tập">
                                <el-option
                                        v-for="item in list_branches"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-if="!isGreaterThanBranch()" class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Vị trí <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="branch_type" class="col-md-9 col-10">
                            <el-radio-group v-model="formCreate.branch_type">
                                <el-radio v-for="(item,index) in list_branch_types" :value=item.value>{{ item.name }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Thêm mới
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
                <el-dialog v-model="dialogVisible">
                    <img w-full :src="dialogImageUrl" alt="Preview Image"/>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';

import constant, {Secret_Key} from '/src/constants/config'
import CryptoJS from "crypto-js";
import md5 from "crypto-js/md5";

export default {
    data() {
        return {
            callback: {
                view: 1
            },
            dialogVisible: false,
            dialogImageUrl: '',
            formCreate: {
                role: '',
                username: '',
                password: '',
                re_password: '',
                fullname: '',
                address: '',
                email: '',
                avatar: '',
                gym_id: '',
                branch_id: '',
                branch_type: '',
                phone: '',
            },
            fileList: [],
            fileAva: '',
            show_input: false,
            rules: {
                role: [
                    {required: true, message: 'Vui lòng chọn vai trò', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            if (value < this.role) {
                                callback(new Error('Không thể tạo account với quyền cao hơn'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
                gym_id: [
                    {required: true, message: 'Vui lòng chọn hệ thống', trigger: 'change'},
                ],
                branch_id: [
                    {required: true, message: 'Vui lòng chọn phòng tập', trigger: 'change'},
                ],
                branch_type: [
                    {required: true, message: 'Vui lòng chọn phòng tập', trigger: 'change'},
                ],
                username: [
                    {required: true, message: 'Vui lòng nhập tên đăng nhập', trigger: 'change'},
                    {pattern: /^\S+$/, message: 'Tên đăng nhập không được chứa dấu cách', trigger: 'change'}
                ],
                fullname: [
                    {required: true, message: 'Vui lòng nhập họ và tên', trigger: 'change'},
                ],
                password: [
                    {required: true, message: 'Vui lòng nhập mật khẩu', trigger: 'change'},
                    {min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const hasLowerCase = /[a-z]/.test(value);
                            const hasUpperCase = /[A-Z]/.test(value);
                            const hasNumber = /\d/.test(value);
                            if (!hasLowerCase || !hasUpperCase || !hasNumber) {
                                callback(new Error('Mật khẩu phải chứa ít nhất một chữ thường, một chữ hoa và một chữ số'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
                re_password: [
                    {required: true, message: 'Vui lòng nhập nhập lại mật khẩu', trigger: 'change'},
                    {min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            if (value !== this.formCreate.password) {
                                callback(new Error('Mật khẩu nhập lại không khớp'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
                phone: [
                    {
                        validator: (rule, value, callback) => {
                            const phoneRegex = /^0[0-9]{9,11}$/;
                            if (!phoneRegex.test(value)) {
                                return callback(new Error('Số điện thoại không hợp lệ'));
                            }
                            return callback();
                        },
                        trigger: 'change'
                    }
                ],
            },
            role: '',
            user: {},
            list_source: [],
            list_gym: [],
            list_branches: [],
            list_branch_types: [
                {
                    'value': 1,
                    'name': 'Lễ tân'
                },
                {
                    'value': 2,
                    'name': 'PT'
                },
                {
                    'value': 3,
                    'name': 'Sale'
                },
                {
                    'value': 4,
                    'name': 'Kiểm soát'
                },
            ]
        }
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('createForm')
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('createForm');
        },
        resetForm(createForm) {
            this.$refs[createForm].resetFields();
        },
        onSubmit(createForm) {
            if (!this.isRoot()) {
                this.formCreate.gym_id = this.user.gym_id
            }
            if (this.role === constant.GYM_BRANCH) {
                this.formCreate.role = constant.GYM_BRANCH
                this.formCreate.branch_id = this.user.branch_id
            }
            if (this.isGreaterThanBranch()) {
                this.formCreate.branch_type = 0
            }
            if (this.formCreate.role < constant.GYM_BRANCH_GROUP) {
                this.formCreate.branch_id = -1
            }
            this.$refs[createForm].validate((valid) => {
                if (valid) {
                    console.log('success')
                    this.onCreate()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async onCreate() {
            let url = '/admin/user-admin'
            let params = {
                username: this.formCreate.username.trim(),
                password: this.formCreate.password,
                gymId: this.formCreate.gym_id,
                fullName: this.formCreate.fullname,
                role: this.formCreate.role < this.role ? this.role : this.formCreate.role,
                email: this.formCreate.email || '',
                phone: this.formCreate.phone || '',
                branch_acc_type: this.formCreate.branch_type,
                addressOfEmp: this.formCreate.address,
                parentId: this.formCreate.gym_id,
            }
            if (this.formCreate.role === constant.GYM_BRANCH_GROUP) {
                params.branchId = -1
                params.branchIds = this.formCreate.branch_id.join(',')
            } else {
                params.branchId = this.formCreate.branch_id
                params.branchIds = ''
            }
            try {
                const res = await post(url, params)
                if (res.code === 200) {
                    this.$swal({
                        icon: 'success',
                        title: "Thông báo",
                        text: 'Tạo tài khoản thành công',
                    }).then((result) => {
                        if (result.value) {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        } else {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'error',
                        title: "Thông báo",
                        text: res.message || 'Tạo tài khoản thất bại',
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        changeRole() {
            this.formCreate.branch_id = ''
        },
        async changeGymID() {
            this.formCreate.branch_id = '';
            if (this.formCreate.gym_id === -1) {
                return
            }
            this.list_branches = await this.getListBranch(this.formCreate.gym_id)
        },

        onChangeAva(file, fileList) {
            let listType = [
                'image/jpeg',
                'image/png',
                'image/jpg',
            ]
            if (!file) {
                this.fileList = []
                this.fileAva = null
                return
            }
            const type = listType.includes(file.raw.type);
            const isLt2M = file.raw.size / 1024 / 1024 < 2;

            if (!type) {
                this.$swal({
                    icon: 'warning',
                    title: "Thông báo",
                    text: 'Vui lòng chọn đúng định dạng ảnh',
                });
                this.fileList = []
                this.fileAva = null
                return
            }
            if (!isLt2M) {
                this.$swal({
                    icon: 'warning',
                    title: "Thông báo",
                    text: 'Vui lòng chọn ảnh nhỏ hơn 2MB',
                });
                this.fileList = []
                this.fileAva = null
                return;
            }
            this.fileList = []
            this.fileList = fileList.filter((item, index) => {
                return index === fileList.length - 1
            })
            this.fileAva = file.raw;
        },
        onPreviewAva(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        onRemoveAva() {
            this.fileList = []
            this.fileAva = null
        },
        clickBack() {
            this.$emit("callback", this.callback);
        },
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        async getListSource() {
            this.list_source = []
            let role = parseInt(this.role)
            if (this.user.username === 'root') {
                this.list_source.push({
                    role: constant.SUPER_ROOT,
                    name: 'Supper Admin'
                })
            }
            if (role <= constant.SUPER_ROOT) {
                this.list_source.push({
                    role: constant.GYM_SYSTEM,
                    name: 'Admin Hệ thống'
                })
            }
            if (role <= constant.GYM_SYSTEM) {
                this.list_source.push({
                    role: constant.GYM_BRANCH_GROUP,
                    name: 'Nhóm phòng tập'
                })
            }
            if (role <= constant.GYM_BRANCH_GROUP) {
                this.list_source.push({
                    role: constant.GYM_BRANCH,
                    name: 'Phòng tập'
                })
            }
            if (this.isRoot()) {
                this.getListSystem()
            } else {
                this.list_branches = await this.getListBranch(this.user.gym_id)
            }
        },
        getUserAndRole() {
            let user =  this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },
        showChooseBranch() {
            return (
                this.formCreate.role === constant.GYM_BRANCH ||
                this.formCreate.role === constant.GYM_BRANCH_GROUP
            )
        },
        isGreaterThanBranch() {
            return parseInt(this.role) < constant.GYM_BRANCH
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_gym = res.rows;
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_gym.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_gym = res.rows
                }
            } else {
                this.list_gym = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        async getListBranch(gymID) {
            try {
                this.$store.dispatch('setLoading', true);
                let request_params = {
                    gymId: gymID,
                }
                let url = 'gym/gymbranch/getByGymId'
                const res = await get(url, request_params)
                if (res.code === 200) {
                    this.$store.dispatch('setLoading', false);
                    return res.rows
                } else {
                    this.$store.dispatch('setLoading', false);
                    return []
                }
            } catch (e) {
                this.$store.dispatch('setLoading', false);
                return []
            }
        },
    },
    beforeMount() {
        this.getUserAndRole()
        this.getListSource()
    },
}
</script>

