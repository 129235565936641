<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalDetailCustomerAccount">Chi tiết tài khoản khách hàng:
                    {{ customer_account.displayName }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body row">
                <div class="col-3 col-md-4">
                    <img v-if="customer_account.avatar"
                         class="b-r-10"
                         :src="customer_account.avatar"
                         alt=""
                         width="100%"/>
                </div>
                <div class="col-9 col-md-8">

                    <table class="table table-lg">
                        <tbody>
                        <tr>
                            <td>Tên đầy đủ:</td>
                            <td>{{ customer_account.realName }}</td>
                        </tr>
                        <tr>
                            <td>Tên hiển thị:</td>
                            <td>{{ customer_account.displayName }}</td>
                        </tr>
                        <tr>
                            <td>Giới tính:</td>
                            <td>{{ customer_account.gender == 0 ? 'Nam' : 'Nữ' }}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{{ customer_account.email }}</td>
                        </tr>
                        <tr>
                            <td>Ngày sinh:</td>
                            <td>{{ customer_account.birthDay }}</td>
                        </tr>
                        <tr>
                            <td>Số điện thoại:</td>
                            <td>{{ customer_account.phoneNumber }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ["customer_account"],
    methods: {},
}
</script>