<template>
    <li class="profile-nav onhover-dropdown pe-0 py-0">
        <div class="media profile-media">
            <img v-if="user.imgavatar"
                 class="b-r-10"
                 :src="user.imgavatar"
                 alt=""
                 style="width:35px; height: 35px"
            />
            <img v-else
                 class="b-r-10"
                 style="width:35px; height: 35px"
                 src="@/assets/images/user.jpg"
                 alt=""
            />
            <div class="media-body">
                <span>{{ user.fullName }}</span>
                <p class="mb-0 font-roboto">
                    {{ user.username }} <i class="middle fa fa-angle-down"></i>
                </p>
            </div>
        </div>
        <ul class="profile-dropdown onhover-show-div">
            <li>
                <a @click="logout"
                >
                    <vue-feather type="log-in"></vue-feather>
                    <span>Đăng xuất</span></a
                >
            </li>
        </ul>
    </li>
</template>

<script>
import CryptoJS from 'crypto-js';
import {Secret_Key} from '/src/constants/config';

export default {
    name: 'Profile',
    data() {
        return {
            user: {}
        }
    },
    mounted() {
        this.getSession()
    },
    methods: {
        getSession() {
            let user =  this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.user = decryptedUser
        },
        logout: function () {
            this.$cookies.keys().forEach(cookie => {
                this.$cookies.remove(cookie);
            });
            this.$router.push('/auth/login');
        }
    },
};
</script>
