<template>
    <div>
        <list-menu v-if="view===1" @callback="callBack($event)"></list-menu>
        <list-action v-if="view===2" @callback="callBack($event)" :menu_update="menu_update"></list-action>
    </div>
</template>

<script>
import ListMenu from "@/pages/menu/list_menu.vue";
import ListAction from "@/pages/menu/list_action_menu.vue";

export default {
    components: {
        'listMenu': ListMenu,
        'listAction': ListAction,
    },
    data() {
        return {
            view: 1,
            menu_update: {},
            role:'',
        };
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.menu_update = step.menu_update ? step.menu_update : {}
        },
    },
};
</script>

