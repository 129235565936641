<template>
    <div>
        <Breadcrumbs main="Quản lý sản phẩm phòng tập" title="Cập nhật sản phẩm phòng tập"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="updateForm"
                        :model="formUpdate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100" v-if="isRoot()">
                        <label class="col-md-3 col-2 align-content-center f-16">Hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="system_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       @change="changeSystemID"
                                       v-model="formUpdate.system_id" style="width:100%" placeholder="Chọn">
                                <el-option
                                        v-for="item in list_system"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Phòng tập<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="gym_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       :disabled="formUpdate.system_id ? false: true"
                                       no-data-text="Không tìm thấy phòng tập nào"
                                       :multiple="true"
                                       v-model="formUpdate.gym_id" style="width:100%" placeholder="Chọn">
                                <el-option
                                        v-for="item in list_gym"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mã sản phẩm<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="code" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.code" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên sản phẩm<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="code" class="col-md-9 col-10">
                            <el-input v-model="formUpdate.name" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Số lượng sản phẩm<span
                                class="font-danger">*</span></label>
                        <el-form-item prop="quantity" class="col-md-9 col-10">
                            <el-input-number v-model="formUpdate.quantity" placeholder="" :min="0"/>
                        </el-form-item>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Cập nhật
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import constant, {Secret_Key} from "@/constants/config";
import CryptoJS from "crypto-js";
import {get, post} from "@/m_utils/rest_api";

export default {
    props: ['product_gym_update'],
    data() {
        return {
            callback: {
                view: 1
            },
            formUpdate: {
                system_id: '',
                gym_id: '',
                code: '',
                name: '',
                quantity: 0,
            },
            rules: {
                system_id: [
                    {required: this.isRoot(), message: 'Vui lòng chọn hệ thống', trigger: 'change'},
                ],
                gym_id: [
                    {required: true, message: 'Vui lòng chọn phòng tập', trigger: 'blur '},
                ],
                code: [
                    {required: true, message: 'Vui lòng nhập mã sản phẩm', trigger: 'blur '},
                ],
                name: [
                    {required: true, message: 'Vui lòng tên sản phẩm', trigger: 'blur '},
                ],
                quantity: [
                    {
                        validator: (rule, value, callback) => {
                            if (value < 0) {
                                callback(new Error('Số lượng sản phẩm phải lớn hơn hoặc bằng 0'));
                            } else {
                                callback(); // Xác thực thành công
                            }
                        },
                        trigger: 'blur'
                    }
                ],
            },
            list_system: [],
            list_gym: [],
            user: {},
            role: '',
        }
    },
    methods: {
        clickBack() {
            this.$emit("callback", this.callback);
        },
        async getUserAndRole() {
            let user = this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
            if (this.role > constant.SUPER_ROOT) {
                this.list_gym = await this.getListGym(this.user.gym_id)
            }
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_system = res.rows;
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system = res.rows
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        async getListGym(system_id) {
            try {
                this.$store.dispatch('setLoading', true);
                let request_params = {
                    gymId: system_id,
                }
                let url = 'gym/gymbranch/getByGymId'
                const res = await get(url, request_params)
                if (res.code === 200) {
                    this.$store.dispatch('setLoading', false);
                    return res.rows
                } else {
                    this.$store.dispatch('setLoading', false);
                    return []
                }
            } catch (e) {
                this.$store.dispatch('setLoading', false);
                return []
            }
        },
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        async changeSystemID() {
            this.formUpdate.gym_id = '';
            this.list_gym = await this.getListGym(this.formUpdate.system_id)
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('updateForm')
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('updateForm');
        },
        resetForm(updateForm) {
            this.$refs[updateForm].resetFields();
        },
        onSubmit(updateForm) {
            this.$refs[updateForm].validate((valid) => {
                if (valid) {
                    this.onCreate()
                } else {
                    return false;
                }
            });
        },
        async setValueForm(product_gym_update) {
            this.formUpdate.gym_id = product_gym_update.gymId
            // if (this.role > constant.SUPER_ROOT) {
            //     this.list_gym = await this.getListGym(this.user.gym_id)
            // } else {
            //     this.list_gym = await this.getListGym(this.formUpdate.system_id)
            // }
            this.formUpdate.name = product_gym_update.productName
            this.formUpdate.code = product_gym_update.productCode
            this.formUpdate.quantity = product_gym_update.quantity
        }
    },
    beforeMount() {
        this.getUserAndRole()
        this.getListSystem()
        this.setValueForm(this.product_gym_update)
    }
}
</script>
<style scoped>

</style>