<template>
    <div>
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img class="img-fluid for-light" src="../assets/images/logo/logo.png"
                                         alt="looginpage"/>
                                </a>
                            </div>
                            <div class="login-main">
                                <div v-if="alert_text!=''"
                                     class="alert alert-warning dark alert-dismissible fade show d-flex" role="alert">
                                    <i data-feather="bell">
                                        <vue-feather type="bell">
                                        </vue-feather>
                                    </i>
                                    <p class="mx-2">{{ alert_text }}</p>
                                    <button class="btn-close" type="button" data-bs-dismiss="alert"
                                            aria-label="Close"></button>
                                </div>
                                <form class="theme-form">
                                    <h4>Đăng nhập</h4>
                                    <div class="form-group">
                                        <label class="col-form-label">Tên đăng nhập</label>
                                        <input class="form-control" type="text" required=""
                                               v-model="user.username.value">
                                        <span class="validate-error"
                                              v-if="!user.username.value">{{
                                                user.username.errormsg
                                            }}</span>

                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Mật khẩu</label>
                                        <div class="form-input position-relative">
                                            <input class="form-control input-password" :type="passwordFieldType"
                                                   name="login[password]"
                                                   required=""
                                                   placeholder="*********" v-model="user.password.value">
                                            <span class="validate-error" v-if="user.password.value.length < 6">{{
                                                    user.password.errormsg
                                                }}</span>

                                            <div class="show-hide" @click="showPassword()">
                                                <span v-if="show"><i class="fa fa-eye-slash"></i> </span>
                                                <span v-else><i class="fa fa-eye"></i> </span>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="validate-error" v-if="user.error.length>0">{{ user.error }}</span>
                                    <div class="form-group mb-0">
                                        <div class="text-end mt-3">
                                            <button class="btn btn-primary btn-block w-100" type="submit"
                                                    @click.prevent="login">Đăng nhập
                                            </button>

                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import error from "auth0-js/src/helper/error";
import md5 from 'crypto-js/md5';
import CryptoJS from 'crypto-js';
import {get,post} from '/src/m_utils/rest_api';
import {Secret_Key} from '/src/constants/config';
import {mapState} from "vuex";
import constants from "/src/constants/config";

export default {
    name: 'login',
    computed: {
        error() {
            return error
        },
        ...mapState({
            alert_login: state => state.alert_login,
        }),
    },
    data() {
        return {
            passwordFieldType: 'password',
            show: true,
            result: {username: '', password: ''},
            user: {
                username: {
                    value: '',
                    errormsg: ''
                },
                password: {
                    value: '',
                    errormsg: ''
                },
                error: '',
            },
            alert_text: ''
        };
    },
    mounted() {
        if (this.alert_login) {
            this.alert_text = this.alert_login
            this.$store.dispatch('setAlert', '');
        }
        this.$cookies.keys().forEach(cookie => {
            this.$cookies.remove(cookie);
        });
    },
    methods: {
        showPassword() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            this.show = !this.show
        },
        async login() {
            if (!this.user.password.value || this.user.password.value.length < 6) {
                this.user.password.errormsg = 'Vui lòng điền mật khẩu và mật khẩu phải lớn hơn 6 ký tự'
            } else {
                this.user.password.errormsg = ''
            }

            if (!this.user.username.value) {
                this.user.username.errormsg = 'Vui lòng điền tài khoản '
            } else {
                this.user.username.errormsg = ''
            }
            if (this.user.username.value && this.user.password.value.length >= 6) {
                let url = "/auth";
                let params = {
                    username: this.user.username.value,
                    password: this.user.password.value,
                }
                const res = await post(url, params)
                if (res.code === 200) {
                    let user = {
                        username: res.user.username,
                        fullName: res.user.fullName || res.user.username,
                        gym_id: res.user.gymId || '',
                        branch_id: res.user.branchId || '',
                        list_branches_id: res.user.branchIds || '',
                        branch_name: res.branchName || '',
                        role: res.user.role.roleId,
                        gym_name: res.gymName || '',
                        branch_acc_type: res.user.branchAccType || 0,
                        branch_img_url: res.imageUrl || '',
                        imgavatar: res.user.avatar || '',
                        sourcesAction: res.sourcesAction,
                    }
                    this.$cookies.set('sessionToken',res.token);
                    if(user.role!==constants.SUPER_ROOT){
                        let get_menu_params= {
                            branch_acc_type: user.branch_acc_type,
                            gym_id: user.gym_id,
                            role_id: user.role,
                        }
                        if(user.role !== constants.GYM_BRANCH_GROUP )
                        {
                            get_menu_params.list_gym_branch_id = user.branch_id ? [user.branch_id]:[]
                        }else {
                            get_menu_params.list_gym_branch_id = user.list_branches_id ? user.list_branches_id.split(','):[]
                        }
                        console.log(get_menu_params)
                        let url_menu ='/admin/menu/get-menu-permission  '
                        const res_menu = await post(url_menu,get_menu_params)
                        if(res_menu.code===200){
                            user.list_menu_item_id = res_menu.data.list_menu_item_id
                            user.list_action = res_menu.data.list_menu_action_id
                        }
                        else {
                            this.user.error = res_menu.message
                            return
                        }
                    }
                    else {
                        user.list_menu_item_id = []
                        user.list_action = []
                    }
                    const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(user), Secret_Key).toString();
                    this.$cookies.set('user',encryptedUser);
                    this.$router.push('/')
                } else {
                    this.user.error = res.message
                }
            }
        },

    },
};
</script>
<style>
.validate-error {
    color: #fc0303;
    font-size: small;
}
</style>