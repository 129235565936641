<template>
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalDetailGym">Chi tiết phòng tập: {{ gym.name }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body ">
                <div class="row">
                    <div class="col-3 col-md-4">
                        <img v-if="gym.imgUrl"
                             class="b-r-10"
                             :src="gym.imgUrl"
                             alt=""
                             width="100%"/>
                    </div>
                    <div class="col-9 col-md-8">
                        <table class="table table-responsive-md">
                            <tbody>
                            <tr>
                                <td>Tên phòng tập:</td>
                                <td>{{ gym.name }}</td>
                            </tr>
                            <tr>
                                <td>Địa chỉ:</td>
                                <td class="text-break">{{ gym.address }}</td>
                            </tr>
                            <tr>
                                <td>Số điện thoại:</td>
                                <td>{{ gym.phone }}</td>
                            </tr>
                            <tr>
                                <td>Giờ mở cửa:</td>
                                <td>{{ gym.timeOpen }}</td>
                            </tr>
                            <tr>
                                <td>Hệ thống:</td>
                                <td>{{ gym.gymName }}</td>
                            </tr>
                            <tr>
                                <td>Trạng thái:</td>
                                <td>{{ gym.status === 3 ? 'Đang hoạt động' : 'Tạm khóa' }}</td>
                            </tr>
                            <tr>
                                <td>Diện tích:</td>
                                <td>{{ gym.area }} m<sup>2</sup></td>
                            </tr>
                            <tr>
                                <td>Ngày tạo:</td>
                                <td>{{ convertDate(gym.createdTime) }}</td>
                            </tr>
                            <tr>
                                <td>Ngày cập nhật:</td>
                                <td>{{ convertDate(gym.updatedTime) }}</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="card mb-0">
                    <div class="card-header card-no-border">
                        <h5>Ảnh giới thiệu</h5>
                    </div>
                    <div class="row">
                        <div class="col-3" v-for="(item,index) in lstImage">
                            <img class="b-r-10 mr-2"
                                 :src="item"
                                 alt=""
                                 width="100%"/>
                        </div>
                    </div>
                </div>
                <div class="card mb-0">
                    <div class="card-header card-no-border">
                        <h5>Các gói tập đang áp dụng</h5>
                    </div>
                    <div class="row">
                        <div class="col-4" v-for="(item, index) in lstPlan">
                            <div class="card widget-1 small-widget bg-warning">
                                <div class="card-body">
                                    <div class="widget-content">
                                        <div class="widget-round primary">
                                            <div class="bg-round">
                                                <svg class="svg-fill">
                                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#tag`"></use>
                                                </svg>
                                                <svg class="half-circle svg-fill bg-warning ">
                                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div><h4>{{ item.packageName }}</h4><a class="f-light">Xem thêm</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-0">
                    <div class="card-header card-no-border">
                        <h5>Các dịch vụ đang áp dụng</h5>
                    </div>
                    <div class="row">
                        <div class="col-4" v-for="(item, index) in lstService">
                            <div class="card widget-1 small-widget bg-success">
                                <div class="card-body">
                                    <div class="widget-content">
                                        <div class="widget-round secondary">
                                            <div class="bg-round">
                                                <svg class="svg-fill">
                                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#tag`"></use>
                                                </svg>
                                                <svg class="half-circle svg-fill bg-success ">
                                                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div><h4>{{ item.name }}</h4><a class="f-light">Xem thêm</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import time_utils from '/src/m_utils/time_utils'
import {get} from '/src/m_utils/rest_api'
import constant from '/src/constants/config'

export default {
    props: ["gym"],
    data() {
        return {
            lstImage: [],
            lstPlan: [],
            lstService: [],
        }
    },
    methods: {
        async getDetail(gym_id) {
            let url = '/gym/admin/gymbranch/' + gym_id;
            this.lstImage = []
            const res = await get(url, {})
            if (res.code === 200) {
                this.lstImage = res.introImages;
            }
        },
        async getPlan(gym_id) {
            let url = '/gym/admin/gympackage/getByBranchId'
            let params = {
                'gymBranchId': gym_id,
                'status': constant.RECORD_ENABLE
            }
            const res = await get(url, params)
            this.lstPlan = []
            if (res.code === 200) {
                this.lstPlan = res.rows
            }
        },
        async getService(gym_id) {
            let url = '/gym/admin/gymservicebranch/findByBranch'
            let params = {
                'branchIdReq': gym_id,
                'status': constant.RECORD_ENABLE,
                'start': 0,
                'limit': 1000
            }
            const res = await get(url, params)
            this.lstService = []
            if (res.code === 200) {
                this.lstService = res.rows
            }
        },
        convertDate(date) {
            return time_utils.convertDate(date);
        },
    },
    watch: {
        gym: function (newVal, oldVal) {
            this.getDetail(newVal.id)
            this.getPlan(newVal.id)
            this.getService(newVal.id)
        }
    }

}
</script>