<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalDetailGym">Cập nhật dịch vụ phòng tập: {{ gym.name }}</h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-4 mr-2" v-for="(item, index) in lstService">
                        <el-checkbox :label="item.name" v-model="item.choose" :value="item.choose" size="large" border/>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-bs-dismiss="modal">Đóng</button>
                <button class="btn btn-primary">Cập nhật</button>
            </div>
        </div>
    </div>
</template>
<script>
import time_utils from '/src/m_utils/time_utils'
import {get} from '/src/m_utils/rest_api'
import constant from '/src/constants/config'

export default {
    props: ["gym"],
    data() {
        return {
            lstServiceChoose: [],
            gymInfo: {},
            lstService: [],
        }
    },
    methods: {
        async getDetail(gym_id) {
            let url = '/gym/admin/gymbranch/' + gym_id;
            this.lstServiceChoose = []
            const res = await get(url, {})
            if (res.code === 200) {
                this.lstServiceChoose = res.services;
                this.gymInfo = res.item
                this.getListService(this.gymInfo.gymId)
            }
        },
        async getListService(gym_id) {
            let url = '/gym/admin/gymservice'
            let params = {
                'gymId': gym_id,
                'status': constant.RECORD_ENABLE,
                'start': 0,
                'limit': 1000,
                'type': 1
            }
            const res = await get(url, params)
            this.lstService = []
            if (res.code === 200) {
                this.lstService = res.rows
                this.lstService.map(item => {
                    let item_service = this.lstServiceChoose.find(service => service.serviceId === item.id)
                    item.choose = false
                    if (item_service) {
                        item.choose = true
                    }
                })
                console.log(this.lstService)
            }
        },
    },
    watch: {
        gym: function (newVal, oldVal) {
            this.getDetail(newVal.id)
        }
    }

}
</script>