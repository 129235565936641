<template>
    <div>
        <Breadcrumbs main="Quản lý phòng tập" title="Thêm mới phòng tập"/>
        <div class="container-fluid">
            <button class="btn btn-primary" @click="clickBack">Quay lại</button>
            <div class="card mt-3">
                <el-form
                        label-position="left"
                        ref="createForm"
                        :model="formCreate"
                        :rules="rules"
                        label-width="auto"
                        class="mt-3 mx-lg-5 mx-3"
                >
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Tên phòng tập <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="name" class="col-md-9 col-10">
                            <el-input v-model="formCreate.name" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100" v-if="isRoot">
                        <label class="col-md-3 col-2 align-content-center f-16">Thuộc hệ thống <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="system_id" class="col-md-9 col-10">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       placeholder=""
                                       v-model="formCreate.system_id" style="width:100%">
                                <el-option
                                        v-for="item in list_system"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Là trụ sở chính</label>
                        <el-form-item prop="is_head_quater" class="col-md-9 col-10">
                            <el-switch
                                    v-model="formCreate.is_head_quater"
                                    size="large"
                                    :active-value="true"
                                    :inactive-value="false"
                            />
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Số điện thoại <span
                                class="font-danger">*</span></label>
                        <el-form-item prop="phone" class="col-md-9 col-10">
                            <el-input v-model="formCreate.phone" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Giờ đóng - mở cửa <span
                                class="font-danger">*</span></label>
                        <div class="col-md-9 col-10 row">
                            <el-form-item prop="start_time" class="col-6">
                                <el-time-select
                                        v-model="formCreate.start_time"
                                        :max-time="formCreate.end_time"
                                        class="mr-4"
                                        placeholder=""
                                        start="00:00"
                                        step="00:15"
                                        end="23:30"
                                />
                            </el-form-item>
                            <el-form-item prop="end_time" class="col-6">
                                <el-time-select
                                        v-model="formCreate.end_time"
                                        :min-time="formCreate.start_time"
                                        placeholder=""
                                        start="00:00"
                                        step="00:15"
                                        end="23:30"
                                />
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Diện tích</label>
                        <el-form-item prop="area" class="col-md-9 col-10">
                            <el-input
                                    v-model="formCreate.area"
                                    placeholder="">
                                <template #append>m<sup>2</sup></template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Thời khóa biểu dịch vụ</label>
                        <el-form-item prop="schedule" class="col-md-9 col-10">
                            <ClientOnly class="w-100">
                                <ckeditor :editor="editor" v-model="formCreate.schedule"></ckeditor>
                            </ClientOnly>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ website</label>
                        <el-form-item prop="web" class="col-md-9 col-10">
                            <el-input v-model="formCreate.web" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ fanpage facebook</label>
                        <el-form-item prop="facebook" class="col-md-9 col-10">
                            <el-input v-model="formCreate.facebook" placeholder=""/>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Mô tả</label>
                        <el-form-item prop="schedule" class="col-md-9 col-10">
                            <ClientOnly class="w-100">
                                <ckeditor :editor="editor" v-model="formCreate.des"></ckeditor>
                            </ClientOnly>
                        </el-form-item>
                    </div>
                    <div class="row w-100">
                        <label class="col-md-3 col-2 align-content-center f-16">Địa chỉ<span
                                class="font-danger">*</span></label>
                        <div class="col-md-9 col-10">
                            <el-form-item prop="address" disabled>
                                <el-input disabled v-model="formCreate.address" placeholder=""/>
                            </el-form-item>
                            <span class="text-primary text-decoration-underline">Nhấp vào vị trí phòng tập của bạn trên bản đồ để lấy địa chỉ và tọa độ phòng tập</span>
                            <br/>
                            <button @click="getLocation" class="btn btn-success my-2">Lấy vị trí hiện tại</button>
                            <div class="w-100">
                                <GoogleMap
                                        :api-key="api_key"
                                        :libraries="['places,geometry']"
                                        style="width: 100%; height: 500px"
                                        :center="center"
                                        :zoom="15"
                                        @click="handleMapClick"
                                >
                                    <Marker v-if="clickedPosition" :options="{ position: clickedPosition }"/>
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                    <el-form-item class="mt-3">
                        <button class="btn btn-primary" @click="handleSubmit">
                            Thêm mới
                        </button>
                        <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import {get, post} from "@/m_utils/rest_api";
import constant, {Secret_Key} from "@/constants/config";
import CryptoJS from "crypto-js";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {GoogleMap, Marker, Autocomplete} from 'vue3-google-map'
import {API_GOOGLE_MAP_KEY} from "@/constants/config";

export default {
    components: {
        'GoogleMap': GoogleMap,
        'Autocomplete ': Autocomplete,
        'Marker': Marker,
        ckeditor: CKEditor.component
    },
    data() {
        return {
            api_key: API_GOOGLE_MAP_KEY,
            callback: {
                view: 1
            },
            role: '',
            user: '',
            formCreate: {
                name: '',
                avatar: '',
                address: '',
                phone: '',
                system_id: '',
                start_time: '',
                end_time: '',
                area: '',
                schedule: '',
                des: '',
                web: '',
                facebook: '',
                is_head_quater: false,
                province: '',
                district: '',
            },
            editor: ClassicEditor,
            list_system: [],
            fileList: [],
            fileAva: '',
            rules: {
                name: [
                    {required: true, message: 'Vui lòng nhập tên phòng tập', trigger: 'change'},
                ],
                system_id: [
                    {required: true, message: 'Vui lòng chọn hệ thống', trigger: 'change'},
                ],
                address: [
                    {required: true, message: 'Vui lòng nhập địa chỉ phòng tập', trigger: 'change'},
                ],
                start_time: [
                    {required: true, message: 'Vui lòng chọn giờ mở cửa', trigger: 'change'},
                ],
                end_time: [
                    {required: true, message: 'Vui lòng chọn giờ đóng cửa', trigger: 'change'},
                ],
                web: [
                    {type: 'url', message: 'Vui lòng nhập định dạng website', trigger: 'change'},
                ],
                facebook: [
                    {type: 'url', message: 'Vui lòng nhập định dạng website', trigger: 'change'},
                ],
                phone: [
                    {required: true, message: 'Vui lòng nhập số điện thoại phòng tập', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            const phoneRegex = /^0[0-9]{9,11}$/;
                            if (!phoneRegex.test(value)) {
                                return callback(new Error('Số điện thoại không hợp lệ'));
                            }
                            return callback();
                        },
                        trigger: 'change'
                    }
                ],
            },
            center: {
                lat: 21.028511,
                lng: 105.804817,
            },
            clickedPosition: {},
        }
    },
    methods: {
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT;
        },
        clickBack() {
            this.$emit("callback", this.callback);
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('createForm')
        },
        onSubmit(createForm) {
            if (!this.isRoot()) {
                this.formCreate.system_id = this.user.gym_id
            }
            this.$refs[createForm].validate((valid) => {
                if (valid) {
                    this.onCreate()
                } else {
                    return false;
                }
            });
        },
        async onCreate() {
            let url = '/gym/admin/gymbranch';
            let params = {
                name: this.formCreate.name,
                gym_id: this.formCreate.system_id,
                is_head_quater: this.formCreate.is_head_quater === true ? 1 : 0,
                address: this.formCreate.address,
                phone: this.formCreate.phone,
                website: this.formCreate.web,
                facebook: this.formCreate.facebook,
                longtitude: this.clickedPosition.lng,
                latitude: this.clickedPosition.lat,
                description: this.formCreate.des,
                timeOpen: this.formCreate.start_time + ' - ' + this.formCreate.end_time,
                area: this.formCreate.area,
                scheduleService: this.formCreate.schedule,
                province: this.formCreate.province,
                district: this.formCreate.district,
                image_url: 'https://vfit-storage.dtsgroup.com.vn/static/user/1507603258539/photo-1-16500970210301840056657-1650097401490-16500974016211262371655-1723014558751.jpg'
            };
            try {
                const res = await post(url, params)
                if (res.code === 200) {
                    this.$swal({
                        icon: 'success',
                        title: "Thông báo",
                        text: 'Tạo phòng tập thành công',
                    }).then((result) => {
                        if (result.value) {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        } else {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'danger',
                        title: "Thông báo",
                        text: res.message || 'Tạo phòng tập thất bại',
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('createForm');
        },
        resetForm(createForm) {
            this.clickedPosition = {}
            this.$refs[createForm].resetFields();
        },
        getUserAndRole() {
            let user =  this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 10) {
                    let requests = []
                    this.list_system = res.rows;
                    for (let i = 10; i < res.total; i += 10) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system = res.rows
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 10,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        async getLocation(event) {
            event.preventDefault();
            try {
                const coordinates = await this.$getLocation();
                this.clickedPosition = {
                    lat: coordinates.lat,
                    lng: coordinates.lng
                };
                this.geocodeLatLng(coordinates.lat, coordinates.lng);
            } catch (error) {
                console.log(error);
            }
        },
        handleMapClick(event) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            this.clickedPosition = {lat, lng};
            this.geocodeLatLng(lat, lng);
        },
        geocodeLatLng(lat, lng) {
            this.geocoder = new google.maps.Geocoder();
            this.geocoder.geocode({location: {lat, lng}}, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        this.formCreate.address = results[0].formatted_address;
                        let address_components = results[0].address_components
                        let lenght_address = address_components.length
                        this.formCreate.province = address_components[lenght_address - 2].long_name
                        this.formCreate.district = address_components[lenght_address - 3].long_name
                    } else {
                        console.log('No results found');
                    }
                } else {
                    console.log('Geocoder failed due to: ' + status);
                }
            });
        },
    },
    beforeMount() {
        this.getUserAndRole();
    },
    mounted() {
        if (this.isRoot()) {
            this.getListSystem();
        }
    }
}
</script>
<style scoped>

</style>