<template>
    <div>
        <Breadcrumbs main="Quản lý phân quyền tài khoản" title="Thêm mới quyền"/>
        <div class="container-fluid">
            <el-form
                    ref="createForm"
                    style="width: 100%"
                    :model="createForm"
                    :rules="rules"
                    label-width="auto"
                    class="demo-ruleForm"
                    label-position="left"
            >
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">Vai trò</label>
                        <el-form-item prop="role">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       v-model="createForm.role" style="width:100%"
                                       placeholder="--Chọn--">
                                <el-option
                                        v-for="item in list_source"
                                        :key="item.role"
                                        :label="item.name"
                                        :value="item.role"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Hệ thống</label>
                        <el-form-item prop="system_id">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       @change="changeSystemID"
                                       v-model="createForm.system_id" style="width:100%"
                                       placeholder="--Chọn--">
                                <el-option
                                        v-for="item in list_system"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-md-3" v-if="isGreaterThanBranch() &&showChooseBranch()">
                        <label class="form-label">Phòng tập</label>
                        <el-form-item prop="gym_id">
                            <el-select clearable
                                       collapse-tags
                                       filterable
                                       :multiple="createForm.role===3"
                                       :disabled="list_gym.length===0"
                                       v-model="createForm.gym_id" style="width:100%"
                                       placeholder="--Chọn--">
                                <el-option
                                        v-for="item in list_gym"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <el-tree
                        style="max-width: 100%"
                        :data="menu"
                        show-checkbox
                        ref="tree"
                        node-key="id"
                        :check-on-click-node="true"
                        :expand-on-click-node="false"
                />
                <el-form-item class="mt-3">
                    <button class="btn btn-primary" @click="handleSubmit">
                        Thêm mới
                    </button>
                    <button class="btn btn-light mx-3" @click="handleReset">Làm lại</button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>
<script>
import constant, {Secret_Key} from "@/constants/config";
import {get, post} from "@/m_utils/rest_api";
import CryptoJS from "crypto-js";
import Tree from "vue3-tree";
import "vue3-tree/dist/style.css"

export default {
    components: {
        Tree
    },
    data() {
        return {
            list_menu: [],
            list_menu_action_choose: [],
            list_action: [],
            list_system: [],
            list_gym: [],
            user: '',
            role: '',
            createForm: {
                role: '',
                system_id: '',
                gym_id: '',
            },
            rules: {
                system_id: [
                    {required: true, message: 'Vui lòng chọn hệ thống', trigger: 'change'},
                ],
                role: [
                    {required: true, message: 'Vui lòng chọn role cho hệ thống', trigger: 'change'},
                ],
                gym_id: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.createForm.role >= 3 && !value) {
                                callback(new Error('Vui lòng chọn phòng tập'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    },
                ],
            },
            callback: {
                view: 1
            },
            menu: [],
            checkedNodes: [],
        }
    },
    methods: {
        getUserAndRole() {
            let user = this.$cookies.get('user');
            let bytes = CryptoJS.AES.decrypt(user, Secret_Key);
            let decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.role = decryptedUser.role
            this.user = decryptedUser
        },
        async getListSource() {
            this.list_source = []
            let role = parseInt(this.role)
            if (this.user.username === 'root') {
                this.list_source.push({
                    role: constant.SUPER_ROOT,
                    name: 'Supper Admin'
                })
            }
            if (role <= constant.SUPER_ROOT) {
                this.list_source.push({
                    role: constant.GYM_SYSTEM,
                    name: 'Admin Hệ thống'
                })
            }
            if (role <= constant.GYM_SYSTEM) {
                this.list_source.push({
                    role: constant.GYM_BRANCH_GROUP,
                    name: 'Nhóm phòng tập'
                })
            }
            if (role <= constant.GYM_BRANCH_GROUP) {
                this.list_source.push({
                    role: constant.GYM_BRANCH,
                    name: 'Phòng tập'
                })
            }
            if (role === constant.GYM_BRANCH) {
                this.list_source.push({
                    role: constant.GYM_BRANCH_LT,
                    name: 'Lễ tân'
                })
                this.list_source.push({
                    role: constant.GYM_BRANCH_PT,
                    name: 'PT'
                })
                this.list_source.push({
                    role: constant.GYM_BRANCH_SALE,
                    name: 'Sale'
                })
                this.list_source.push({
                    role: constant.GYM_BRANCH_KS,
                    name: 'Kiểm soát'
                })
            }

            if (this.isRoot()) {
                this.getListSystem()
            } else {
                this.list_gym = await this.getListBranch(this.user.gym_id)
                this.createForm.system_id = this.user.gym_id
            }
            if (role === constant.GYM_BRANCH) {
                this.createForm.system_id = this.user.gym_id
                this.createForm.gym_id = this.user.branch_id
            }
        },
        isRoot() {
            return parseInt(this.role) === constant.SUPER_ROOT
        },
        async getListSystem() {
            let url = '/gym/admin/gym'
            let params = {
                'start': 0,
                'limit': 50,
                'status': constant.RECORD_ENABLE,
            }
            this.$store.dispatch('setLoading', true);
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 50) {
                    let requests = []
                    this.list_system = res.rows;
                    for (let i = 50; i < res.total; i += 50) {
                        requests.push(this.fetchDsSystem(i))
                    }
                    try {
                        const results = await Promise.all(requests);
                        const allPosts = results.flat();
                        allPosts.forEach((response, index) => {
                            this.list_system.push(response);
                        });
                        this.$store.dispatch('setLoading', false);

                    } catch (e) {
                        console.log(e)
                        this.$store.dispatch('setLoading', false);
                    }
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_system = res.rows
                }
            } else {
                this.list_system = []
            }
        },
        async fetchDsSystem(start) {
            let url = '/gym/admin/gym'
            let params = {
                'start': start,
                'limit': 50,
                'status': constant.RECORD_ENABLE,
            }
            try {
                const res = await get(url, params);
                return res.rows
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        async getListBranch(system_id) {
            try {
                this.$store.dispatch('setLoading', true);
                let request_params = {
                    gymId: system_id,
                }
                let url = 'gym/gymbranch/getByGymId'
                const res = await get(url, request_params)
                if (res.code === 200) {
                    this.$store.dispatch('setLoading', false);
                    return res.rows
                } else {
                    this.$store.dispatch('setLoading', false);
                    return []
                }
            } catch (e) {
                this.$store.dispatch('setLoading', false);
                return []
            }
        },
        showChooseBranch() {
            return (
                this.createForm.role === constant.GYM_BRANCH ||
                this.createForm.role === constant.GYM_BRANCH_GROUP
            )
        },
        isGreaterThanBranch() {
            return parseInt(this.role) < constant.GYM_BRANCH
        },
        async changeSystemID() {
            this.createForm.gym_id = '';
            if (this.createForm.system_id === -1) {
                return
            }
            this.list_gym = await this.getListBranch(this.createForm.system_id)
        },
        async getListMenuItem() {
            let url = 'admin/menu/menu-item';
            let params = {
                'start': 0,
                'limit': 50
            }
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 50) {
                    let requests = []
                    this.list_menu = res.list_data;
                    for (let i = 50; i < res.total; i += 50) {
                        requests.push(this.fetchDsMenu(i))
                        try {
                            const results = await Promise.all(requests);
                            const allPosts = results.flat();
                            allPosts.forEach((response, index) => {
                                this.list_menu.push(response);
                            });
                            this.$store.dispatch('setLoading', false);

                        } catch (e) {
                            console.log(e)
                            this.$store.dispatch('setLoading', false);
                        }
                    }
                    this.list_action = await this.getListMenuAction()
                    this.menu = this.mapMenuTree(this.list_menu, this.list_action)
                } else {
                    this.$store.dispatch('setLoading', false);
                    this.list_menu = res.list_data
                    this.list_action = await this.getListMenuAction()
                    this.menu = this.mapMenuTree(this.list_menu, this.list_action)
                }
            } else {
                this.list_menu = []
            }
        },
        async fetchDsMenu(start) {
            let url = '/admin/menu/menu-item'
            let params = {
                'start': start,
                'limit': 50,
            }
            try {
                const res = await get(url, params);
                return res.list_data
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },

        mapMenuTree(list_menu, list_action) {
            let id = '';
            let name = '';
            let parent_id = '';
            let children = [];
            let menu = []
            list_menu.map(itemMenu => {
                if (!itemMenu.children) {
                    itemMenu.children = []
                }
                let itemAction = list_action.find(item => item.menu_id === itemMenu.id)
                if (itemAction) {
                    let a = {
                        id: itemAction.id,
                        label: itemAction.name,
                        menu_id: itemAction.menu_id,
                        type: 'action',
                    }
                    itemMenu.children.push(a)
                }
            })
            list_menu.forEach((item, index) => {
                if (item.level === 1) {
                    id = item.id;
                    name = item.name;
                    parent_id = item.parent_id;
                    children = item.children;
                    let a = {
                        id: id,
                        label: name,
                        children: children,
                        type: 'item',
                    }
                    menu.push(a)
                }
                if (item.level === 2) {
                    id = item.id;
                    name = item.name;
                    parent_id = item.parent_id;
                    children = item.children;
                    let a = {
                        id: id,
                        label: name,
                        children: children,
                        type: 'item',
                    }
                    menu.map(itemMenu => {
                        if (itemMenu.id === parent_id) {
                            itemMenu.children.push(a)
                        }
                    })
                }
            })
            return menu
        },
        async getListMenuAction() {
            let url = 'admin/menu/menu-action';
            let params = {
                'start': 0,
                'limit': 50
            }
            const res = await get(url, params)
            if (res.code === 200) {
                if (res.total > 50) {
                    let requests = []
                    let list_action = res.list_data;
                    for (let i = 50; i < res.total; i += 50) {
                        requests.push(this.fetchDsMenuAction(i))
                        try {
                            const results = await Promise.all(requests);
                            const allPosts = results.flat();
                            allPosts.forEach((response, index) => {
                                list_action.push(response);
                            });
                            this.$store.dispatch('setLoading', false);

                        } catch (e) {
                            console.log(e)
                            this.$store.dispatch('setLoading', false);
                        }
                    }
                    return list_action
                } else {
                    this.$store.dispatch('setLoading', false);
                    return res.list_data
                }
                // this.list_menu = this.buildHierarchy(this.list_menu)
                // console.log(this.list_menu)
            } else {
                return []
            }
        },
        async fetchDsMenuAction(start) {
            let url = '/admin/menu/menu-action'
            let params = {
                'start': start,
                'limit': 50,
            }
            try {
                const res = await get(url, params);
                return res.list_data
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        handleSubmit(event) {
            event.preventDefault();
            this.onSubmit('createForm')
        },
        handleReset(event) {
            event.preventDefault();
            this.resetForm('createForm');
        },
        onSubmit(createForm) {
            this.$refs[createForm].validate((valid) => {
                this.list_menu_action_choose = this.$refs.tree.getCheckedNodes()
                if (this.list_menu_action_choose.length === 0) {
                    this.$swal({
                        icon: 'error',
                        title: "Thông báo",
                        text: 'Vui lòng chọn ít nhất 1 chức năng',
                    })
                    return
                }
                if (valid) {
                    this.onCreate()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async onCreate() {
            let url = '/admin/menu/menu-permission';
            let lst_action = []
            let lst_item = []
            this.list_menu_action_choose.forEach(item => {
                if (item.type === 'item') {
                    lst_item.push(item.id)
                } else {
                    lst_action.push(item.id)
                }
            })
            let params = {
                role_id: this.role == constant.GYM_BRANCH ? constant.GYM_BRANCH : this.createForm.role,
                gym_id: this.createForm.system_id,
                list_menu_action_id: lst_action,
                list_menu_item_id: lst_item,
                branch_acc_type: this.role == constant.GYM_BRANCH ? this.createForm.role : 0
            }
            if (this.createForm.gym_id) {
                params.list_gym_branch_id = Array.isArray(this.createForm.gym_id) ? this.createForm.gym_id : [this.createForm.gym_id]
            }
            if (this.role != constant.GYM_BRANCH && this.createForm.role< constant.GYM_BRANCH_GROUP) {
                params.list_gym_branch_id =[-1]
            }
            try {
                const res = await post(url, params)
                if (res.code === 200) {
                    this.$swal({
                        icon: 'success',
                        title: "Thông báo",
                        text: 'Tạo phân quyền thành công',
                    }).then((result) => {
                        if (result.value) {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        } else {
                            this.$refs['createForm'].resetFields();
                            this.clickBack()
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'error',
                        title: "Thông báo",
                        text: res.message || 'Tạo phân quyền thất bại',
                    })
                }
            } catch (e) {
                console.log(e)
            }

        },
        clickBack() {
            this.$emit("callback", this.callback);
        },
    },
    beforeMount() {
        this.getUserAndRole()
        this.getListSource()
        this.getListMenuItem()
    }
}
</script>
<style scoped>

</style>