<template>
    <div>
        <list-product-gym v-if="view===1" @callback="callBack($event)"></list-product-gym>
        <create-product-gym v-if="view===2" @callback="callBack($event)"></create-product-gym>
        <update-product-gym v-if="view===3" @callback="callBack($event)" :product_gym_update="product_update"></update-product-gym>
    </div>
</template>

<script>
import ListProductGym from "@/pages/product_gym/list_product_gym.vue";
import CreateProductGym from "@/pages/product_gym/create_product_gym.vue";
import UpdateProductGym from "@/pages/product_gym/update_product_gym.vue";


export default {
    components: {
        'listProductGym': ListProductGym,
        'createProductGym': CreateProductGym,
        'updateProductGym': UpdateProductGym,

    },
    data() {
        return {
            view: 1,
            product_update: {},
        };
    },
    methods: {
        callBack(step) {
            this.view = step.view
            this.product_update = step.product_update ? step.product_update : {}
        },
    },
};
</script>

