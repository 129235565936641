import axios from 'axios';
import {BASE_URL_API} from '/src/constants/config';
import router from '/src/router/index';
import store from '/src/store'
import VueCookies from 'vue-cookies'

const getToken = () => {
    return VueCookies.get('sessionToken');
};

const api = axios.create({
    baseURL: BASE_URL_API,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        config.headers['token'] = token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});
const handleRequestError = (error) => {
    if (error.response) {
        if (error.response.status == 409) {
            VueCookies.keys().forEach(cookie => {
                VueCookies.remove(cookie);
            });
            store.dispatch('setAlert', error.response.data.message)
            router.push('/auth/login');
        }
    } else if (error.request) {
        console.error('No response received:', error.request);
    } else {
        console.error('Error in request setup:', error.message);
    }
};
// Function to perform a GET request
export const get = async (endpoint, params = {}) => {
    try {
        const response = await api.get(endpoint, {params});
        return response.data;
    } catch (error) {
        handleRequestError(error);
    }
};

// Function to perform a POST request
export const post = async (endpoint, data) => {
    try {
        const response = await api.post(endpoint, data);
        return response.data;
    } catch (error) {
        handleRequestError(error);
    }
};

// Function to perform a PUT request
export const put = async (endpoint, data) => {
    try {
        const response = await api.put(endpoint, data);
        return response.data;
    } catch (error) {
        handleRequestError(error);
    }
};

// Function to perform a DELETE request
export const remove = async (endpoint) => {
    try {
        const response = await api.delete(endpoint);
        return response.data;
    } catch (error) {
        handleRequestError(error);
    }
};

// Usage examples:
// import { get, post, put, remove } from './api';

// get('/path/to/resource').then(data => console.log(data));
// post('/path/to/resource', { key: 'value' }).then(data => console.log(data));
// put('/path/to/resource', { key: 'updatedValue' }).then(data => console.log(data));
// remove('/path/to/resource').then(data => console.log(data));