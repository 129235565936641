<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modalDetailService">Chi tiết dịch vụ: {{ service.name }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body row">
                <div class="col-3 col-md-4">
                    <img v-if="service.image"
                         class="b-r-10"
                         :src="service.image"
                         alt=""
                         width="100%"/>
                </div>
                <div class="col-9 col-md-8">
                    <table class="table table-responsive-md">
                        <tbody>
                        <tr>
                            <td>Tên dịch vụ:</td>
                            <td>{{ service.name }}</td>
                        </tr>
                        <tr>
                            <td>Hệ thống:</td>
                            <td class="text-break">{{ service.gymName }}</td>
                        </tr>
                        <tr>
                            <td>Phòng tập:</td>
                            <td>
                                <p class="text-break" v-for="(item,index) in lstGym">- {{ item.branchName }}</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import constant from '/src/constants/config'
import {get} from '/src/m_utils/rest_api';

export default {
    props: ["service"],
    data() {
        return {
            lstGym: [],
        }
    },
    methods: {
        async getListGym(service_id) {
            let url = '/gym/admin/gymservicebranch/service/' + service_id
            let params = {
                'status': constant.RECORD_ENABLE
            }
            const res = await get(url, params)
            this.lstGym = []
            if (res.code === 200) {
                this.lstGym = res.rows;
            }
        }
    },
    watch: {
        service: function (newVal, oldVal) {
            if (newVal) {
                this.getListGym(newVal.id)
            }
        },
    }
}
</script>