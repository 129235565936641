<template>
    <div>
        <div class="container-fluid">
            <div class="page-title">
                <div class="row">
                    <div class="col-6 d-flex">
                        <h3 v-if="title &&!sub">{{ title }}</h3>
                        <h3 v-if="sub">{{ sub }}</h3>
                    </div>
                    <div class="col-6">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{ path: '/' }">
                                    <svg class="stroke-icon">
                                        <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
                                    </svg>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item" v-if="main">{{ main }}</li>
                            <li :class="sub?'breadcrumb-item':'breadcrumb-item active'" v-if="title">{{ title }}</li>
                            <li class="breadcrumb-item active" v-if="sub">{{ sub }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        sub: {
            default: ''
        },
        title: {
            default: ''
        },
        main: {
            default: ''
        }
    }
};
</script>
